<template>
  <div class="neo__product-specs-block__inner">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NeoProdcutSpecsTable",
  props: {
    serviceUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableFields: null,
    };
  },
  computed: {},
  mounted() {
    this.getTableFields();
  },
  methods: {
    getTableFields() {
      this.tableFields = document.querySelectorAll(
        ".neo__product-specs-block__inner td a"
      );
      if (this.tableFields.length > 0) {
        this.checkTableFields();
      }
    },
    checkTableFields() {
      const self = this;
      this.tableFields.forEach(function (element) {
        if (element.href.includes("#")) {
          if (element.href.split("#").length > 1) {
            const link = element.href.split("#")[1];
            if (link.includes(".png")) {
              self.renderImage(element, link);
            } else if (link.includes(".pdf")) {
              self.renderLink(element, link);
            } else if (link.includes("adviestool")) {
              self.renderTool(element);
            }
          }
        }
      });
    },
    renderImage(element, link) {
      element.outerHTML = `<img
        src="${this.serviceUrl}/smallLabel/ltr/90/${link}" 
        alt="energy label">`;
    },
    renderLink(element, link) {
      element.outerHTML = `<a
        class="neo__cta link" 
        href="${this.serviceUrl}/pdf/nl/${link}" 
        target="_Blank" 
        download>
        Download
      </a>`;
    },
    renderTool(element) {
      element.outerHTML = `<button
        aria-lable="start advicetool" 
        class="neo__cta link adviestool" 
        target="_Blank">
        ${element.innerHTML}
      </a>`;
    },
  },
};
</script>
