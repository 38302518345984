<template>
  <div class="partial__block partial__neo-feat-list">
    <div class="neo-feat-list__innerbackground"></div>
    <carousel
      :navigation-enabled="true"
      navigation-next-label=""
      navigation-prev-label=""
      :navigation-click-target-size="36"
      :pagination-enabled="true"
      :per-page-custom="[
        [0, Math.min(parsedProducts.length, 1)], //shows 1 items from 0 Pixels to 568 Pixels
        [768, Math.min(parsedProducts.length, 2)], //shows 2 items from 568 Pixels to 972 Pixels
        [992, Math.min(parsedProducts.length, 3)], //shows 3 items from 972 Pixels to 1600 Pixels
        [1750, Math.min(parsedProducts.length, 4)], // shows 4 items above 1750 pixels
      ]"
      :scroll-per-page="true"
      class="neo-feat-list__carousel"
    >
      <slide
        v-for="(product, index) in parsedProducts"
        :key="`products__${index}`"
        class="carousel__slide"
      >
        <div v-if="product" class="slide--partial">
          <div class="slide--header">
            <div class="slide__header--image">
              <UiImage
                v-if="product.imgsrc"
                :src="product.imgsrc"
                :alt="'product categorie'"
              ></UiImage>
            </div>
            <h3
              v-if="product.title"
              class="slide__header--title"
              v-text="product.title"
            ></h3>
            <div
              v-if="product.text"
              class="slide__header--description wysiwyg"
              v-html="product.text"
            ></div>
          </div>

          <div class="slide--content slide__list">
            <div
              v-for="(feat, i) in visableProducts(product.featured)"
              :key="`feat__${i}`"
              class="slide__list__item"
            >
              <a :href="feat.url" class="item--link">
                <div class="item--link__image">
                  <UiImage
                    v-if="feat.imageSrc"
                    :src="feat.imageSrc"
                    :alt="'product categorie'"
                  ></UiImage>
                </div>
                <div
                  v-if="feat.title"
                  class="item--text"
                  v-text="feat.title"
                ></div>
              </a>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import UiImage from "./UiImage.vue";

export default {
  name: "PartialsNeoFeatListBl",
  components: {
    carousel: Carousel,
    slide: Slide,
    UiImage,
  },
  props: {
    productsString: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    parsedProducts() {
      return JSON.parse(this.productsString);
    },
  },
  methods: {
    visableProducts(data) {
      return data.filter(function (product) {
        return product && "imageSrc" in product;
      });
    },
  },
};
</script>
