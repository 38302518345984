<template>
  <a
    :href="itemLink"
    :target="cta.target ? '_blank' : null"
    :rel="cta.target"
    class="cta"
    :class="[
      classes,
      icon ? `icon--${icon}` : '',
      containsAdviestool ? 'adviestool' : '',
    ]"
    :aria-label="cta.text"
  >
    <span v-html="itemText"></span>
  </a>
</template>

<script>
export default {
  name: "UiLink",
  props: {
    classes: {
      type: String,
      default: "link",
    },
    icon: {
      type: String,
      default: null,
    },
    cta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    containsAdviestool() {
      return this.cta.url.includes("adviestool");
    },
    itemLink() {
      let linkUrl = this.cta.url;
      linkUrl = linkUrl.replace("#adviestool", "?tool=adviestool");
      linkUrl = linkUrl.replace("/?tool", "?tool");
      return linkUrl;
    },
    itemText() {
      return this.cta.title.replace(
        "$pro",
        '<span class="pro-label">Pro</span>'
      );
    },
  },
  methods: {
    emitBtnClick() {
      this.$emit("btnClick");
    },
  },
};
</script>
