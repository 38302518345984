<template>
  <div v-if="content" class="locator-block">
    <h3
      v-if="
        parsedContent.locatorBlockTitle &&
        parsedContent.locatorBlockTitle.length > 0
      "
      class="h4 locator-block__title"
    >
      {{ parsedContent.locatorBlockTitle }}
    </h3>
    <p v-if="parsedContent.locatorBlockText" class="locator-block__text">
      {{ parsedContent.locatorBlockText }}
    </p>

    <NeoImage
      v-if="
        parsedContent.locatorBlockImage &&
        parsedContent.locatorBlockImage.length
      "
      :class="'locator-block__image'"
      :src="parsedContent.locatorBlockImage[0].url"
      :alt="'Dealer Locator image'"
    />

    <form class="locator-block__form" @submit.prevent="submitLocation">
      <label class="locator-block__label--textinput">
        <input
          v-model="locatorInput"
          class="locator-block__input"
          placeholder="Jouw postcode"
          type="text"
        />
      </label>
      <label class="locator-block__label--submit">
        <button
          class="locator-block__submit cta__button desktop"
          @click.prevent="submitLocation"
        >
          Zoeken
        </button>
        <button
          class="locator-block__submit cta__button mobile"
          @click.prevent="submitLocation"
        >
          <NeoImage
            :class="'search-img'"
            :src="'https://s3.eu-west-1.amazonaws.com/np-nefit/Iconen/search-white.png'"
            :alt="'Search button Icon'"
          />
        </button>
      </label>
    </form>
  </div>
</template>

<script>
import NeoImage from "./NeoImage.vue";
export default {
  name: "LocatorBlock",
  components: {
    NeoImage,
  },
  props: {
    content: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      locatorInput: "",
    };
  },
  computed: {
    parsedContent() {
      return JSON.parse(this.content);
    },
  },
  methods: {
    submitLocation() {
      window.location =
        "/consument/vind-een-installateur?place=" + this.locatorInput;
    },
  },
};
</script>
