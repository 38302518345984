<template>
  <div v-if="currentItem.url" class="neo__product-details-block__images">
    <div class="neo__product-details-block__images-big">
      <div class="neo__image-loading__container">
        <img
          class="image"
          style="background-position: 50% 50%; object-position: 50% 50%"
          :alt="currentItem.title ? currentItem.title : currentItem.filename"
          :aria-label="
            currentItem.title ? currentItem.title : currentItem.filename
          "
          :src="currentItem.url"
        />
      </div>
    </div>

    <ul>
      <li
        v-for="(image, index) in parsedImages"
        :key="index"
        :class="{ 'neo_product-details_active-image': currentImage === index }"
      >
        <button @click="setCurrentImage(index)">
          <NeoImage :src="image.url" :alt="image.title"></NeoImage>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import NeoImage from "./NeoImage.vue";

export default {
  name: "NeoProductDetails",
  components: {
    NeoImage,
  },
  props: {
    images: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentImage: 0,
    };
  },
  computed: {
    currentItem() {
      return this.parsedImages[this.currentImage];
    },
    parsedImages() {
      return JSON.parse(this.images);
    },
  },
  methods: {
    setCurrentImage(e) {
      this.currentImage = this.currentImage !== e ? e : this.currentImage;
    },
  },
};
</script>
