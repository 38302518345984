<template>
  <div class="savings-graph">
    <div class="graph-circle">
      <div class="graph-circle__grey"></div>
      <div
        class="graph-circle__active"
        :style="{ transform: `rotate(${degrees}deg)` }"
      ></div>
      <h3><small>ca.</small> {{ value }}</h3>
    </div>
    <p>{{ copy }}</p>
  </div>
</template>

<script>
export default {
  name: "SavingsGraph",
  props: {
    percentage: {
      type: Number,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    copy: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      degrees: -179,
    };
  },
  mounted() {
    setTimeout(() => {
      // Wait a bit so we can see the animation
      this.setDegrees();
    }, 1000);
  },
  methods: {
    setDegrees() {
      const startDegrees = -180; // Start position for the rotation
      const percentageToDegrees = (180 / 100) * this.percentage; // Amount of degrees needed
      this.degrees = startDegrees + percentageToDegrees;
    },
  },
};
</script>
