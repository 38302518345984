<template>
  <div class="savings-savings savings-card savings-block">
    <h3 class="savings-card__title savings-card__title--savings">
      {{ title }}
    </h3>

    <div class="savings-card__content savings-card-info">
      <SavingsGraph
        :percentage="100 - savings.gas_percentage"
        :value="`€${savings.per_year},-`"
        copy="verwachte besparing energiekosten per jaar *"
        class="savings-card-info__graph"
      />

      <div class="savings-card-info__content">
        <div class="savings-item">
          <div class="icon icon--gas"></div>
          <p class="metric-value">
            c.a. {{ savings.gas_savings }} m³
            <span>verwachte afname gasverbruik</span>
          </p>
          <p class="savings-percentage savings-percentage--down">
            <i class="fa-solid fa-chevron-down"></i>
            {{ savings.gas_percentage }}%
          </p>
        </div>

        <div class="savings-item">
          <div class="icon icon--electricity"></div>
          <p class="metric-value">
            c.a. {{ savings.electricity_total }} KWh
            <span>verwachte toename stroomverbruik</span>
          </p>
          <p class="savings-percentage savings-percentage--up">
            <i class="fa-solid fa-chevron-up"></i>
            {{ savings.electricity_percentage }}%
          </p>
        </div>

        <div class="savings-item">
          <div class="icon icon--c02"></div>
          <p class="metric-value">
            c.a. {{ savings.co2_total }} kg
            <span>verwachte besparing CO2 per jaar</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SavingsGraph from "./SavingsGraph.vue";
export default {
  name: "SavingsSavings",
  components: { SavingsGraph },
  props: {
    title: {
      type: String,
      default: null,
    },
    savings: {
      type: Object,
      default: null,
    },
  },
};
</script>
