/* IE11 forEach polyfill */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (document.readyState !== "loading") {
  myInitCode();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    myInitCode();
  });
}

function myInitCode() {
  const switchElement = document.querySelectorAll(
    ".product-comparison__switch"
  );
  const products = document.querySelectorAll(".product__price-amount");
  let buttons = null;

  if (switchElement && switchElement.length > 0) {
    buttons = switchElement[0].childNodes;
    // Click on submit
    buttons.forEach(function (btn, index) {
      btn.addEventListener("click", function () {
        switchButtonState(index);
        switchProductState(index);
      });
    });
  }

  function switchButtonState(event) {
    buttons.forEach(function (btn, index) {
      if (event === index) {
        btn.classList.add("is-active");
      } else if (btn.classList.contains("is-active")) {
        btn.classList.remove("is-active");
      }
    });
  }

  function switchProductState(event) {
    let value;
    if (event === 0) {
      value = "buy-active";
    } else {
      value = "rent-active";
    }

    products.forEach(function (product) {
      if (product.classList.contains("buy-active")) {
        product.classList.remove("buy-active");
      }
      if (product.classList.contains("rent-active")) {
        product.classList.remove("rent-active");
      }
      product.classList.add(value);
    });
  }
}
