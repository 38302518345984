<template>
  <div class="compare-tile__link">
    <div class="compare-tile__wrapper">
      <div class="compare-tile__wrapper-inner">
        <img
          class="compare-tile__image"
          :src="item.productImage"
          alt="Product Image"
        />
        <div class="compare-tile__content">
          <h5 class="compare-tile__title">{{ item.tileTitle }}</h5>
          <p class="compare-tile__category">{{ item.productCategory }}</p>
          <h6 class="compare-tile__product-title">{{ item.productTitle }}</h6>
          <div class="compare-tile__bullet-list" v-html="item.bulletList"></div>
        </div>
        <div class="compare-tile__list-wrapper">
          <div
            v-for="(block, index) in item.descriptionList"
            :key="index"
            class="compare-tile__list-block"
          >
            <p class="compare-tile__list-block-title">{{ block.term }}</p>
            <div class="compare-tile__list-block-detail">
              <img
                v-if="block.icon.value == 'coins'"
                class="compare-tile__list-block-image"
                width="24"
                height="24"
                src="https://s3.eu-west-1.amazonaws.com/np-nefit-pro-staging/Iconen/compare-icon-coins.svg"
                alt="Coins"
              />
              <img
                v-else-if="block.icon.value == 'clock'"
                class="compare-tile__list-block-image"
                width="24"
                height="24"
                src="https://s3.eu-west-1.amazonaws.com/np-nefit-pro-staging/Iconen/compare-icon-history.svg"
                alt="Clock"
              />
              <img
                v-else-if="block.icon.value == 'piggybank'"
                class="compare-tile__list-block-image"
                width="24"
                height="24"
                src="https://s3.eu-west-1.amazonaws.com/np-nefit-pro-staging/Iconen/compare-icon-piggybank.svg"
                alt="Clock"
              />
              <img
                v-else-if="block.icon.value == 'euroArrow'"
                class="compare-tile__list-block-image"
                width="24"
                height="24"
                src="https://s3.eu-west-1.amazonaws.com/np-nefit-pro-staging/Iconen/compare-icon-euro-arrows.svg"
                alt="Clock"
              />
              <p class="compare-tile__list-block-value">
                {{ block.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="refCta"
        class="compare-tile__cta-wrapper"
        :style="buttonWrapperStyle"
      >
        <a
          v-if="item.primaryCta.url"
          :href="item.primaryCta.url"
          class="compare-tile__cta-btn compare-tile__cta-btn--primary"
          >{{
            item.primaryCta.customText ? item.primaryCta.customText : "Doe mee"
          }}</a
        >
        <a
          v-if="item.secondaryCta.url"
          :href="item.secondaryCta.url"
          class="compare-tile__cta-btn compare-tile__cta-btn--secondary"
          >{{
            item.secondaryCta.customText
              ? item.secondaryCta.customText
              : "Doe mee"
          }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NeoCompareBlockTile",
  props: {
    item: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    buttonWrapperStyle() {
      if (this.height > 0) {
        return `height: ${this.height}px;`;
      }
      return "";
    },
  },
  mounted() {
    const { refCta } = this.$refs;
    if (refCta) {
      const computedHeight = refCta.getBoundingClientRect().height;
      this.$emit("computed-height", computedHeight);
    }
  },
};
</script>
