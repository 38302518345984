<template>
  <div>
    <section class="l-section-contact-searcher">
      <div class="c-contact-searcher">
        <div class="c-contact-searcher__content">
          <h2 class="block--title">{{ content.title }}</h2>
          <p>
            {{ content.description }}
          </p>
        </div>
        <div class="c-contact-searcher__search-bar">
          <input
            v-model="query"
            type="text"
            class="c-contact-searcher__search-bar-input"
            :placeholder="content.searchBarPlaceHolder"
            @keyup.enter="searchResults"
          />
          <div class="c-contact-searcher__search-bar-button">
            <button
              class="btn btn--red btn--full"
              :disabled="buttonIsDisabled"
              @click="searchResults"
            >
              {{ content.searchBarButtonText }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <div v-if="!isEmpty(results) || loading">
      <section
        v-if="loading"
        class="block block--contactperson-block bg--waves"
      >
        <div class="container">
          <div class="block--outer u-flex-center">
            <spinner />
          </div>
        </div>
      </section>
      <contact-persons v-else :persons="results">
        {{ content.sectionPersonsTitle }}
      </contact-persons>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { isEmpty } from "lodash";
import spinner from "../Spinner.vue";

export default {
  name: "ContactPersonsSearcher",
  components: {
    spinner,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      results: [],
      query: "",
    };
  },
  computed: {
    buttonIsDisabled() {
      return this.query.length < 4;
    },
  },
  methods: {
    isEmpty,
    async searchResults() {
      if (this.query.length < 4) {
        this.results = [];
        return;
      }
      this.loading = true;

      try {
        const results = await axios.get("/api/contact-persons/search", {
          params: {
            q: this.query,
          },
        });

        this.results = [];

        if (!isEmpty(results.data) && !isEmpty(results.data.data)) {
          this.results = results.data.data;
        }

        this.loading = false;
      } catch (error) {
        console.error(error);

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.l-section-contact-searcher {
  padding-top: 64px;
  padding-bottom: 92px;
}

.c-contact-searcher {
  @include container;

  &__content {
    grid-column: 2 / -2;
    text-align: center;
    margin-bottom: 56px;

    @include tablet {
      grid-column: 4 / -4;
    }
  }

  &__search-bar {
    grid-column: 3 / -3;
    display: flex;
    row-gap: 12px;
    flex-wrap: wrap;

    @include tablet {
      grid-column: 4 / -4;
      justify-content: center;
      column-gap: 16px;
    }

    @include desktop {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;
      grid-column: 4 / -4;
    }

    @include widescreen {
      grid-column: 5 / -5;
    }
  }

  &__search-bar-input {
    @include basic-reset;

    width: 100%;
    font-size: 18px;
    height: 48px;
    border-bottom: 2px solid #7a7a7a;
    background: #e0e2e5;
    padding-left: 12px;
    padding-right: 12px;

    &::placeholder {
      color: #9da2a8;
    }

    @include tablet {
      flex: 1 1 auto;
      height: 56px;
    }
  }

  &__search-bar-button {
    width: 100%;
    height: 48px;
    @include basic-reset;
    display: flex;
    justify-content: center;

    @include tablet {
      flex: 0 0 320px;
      cursor: pointer;
    }
  }
}
</style>
