<template>
  <nav v-if="links" class="anchor-nav__list">
    <a
      v-for="(link, index) in links"
      :key="`anchosr-link__${index}`"
      class="anchor-nav__list__item"
      :class="{ 'anchor--active': storedHash === link.url }"
      :href="link.url"
      :target="link.target"
      :alt="link.text"
      @click="moveHash()"
    >
      <span v-html="link.text"></span>
    </a>
  </nav>
</template>

<script>
export default {
  name: "PartialsAnchorNav",
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      storedHash: null,
    };
  },
  watch: {
    storedHash() {
      if (this.storedHash) {
        this.scrollToHash(this.storedHash);
      }
    },
  },
  mounted() {
    this.getCurrentHash();
  },
  methods: {
    moveHash() {
      setTimeout(
        function () {
          this.getCurrentHash();
        }.bind(this),
        200
      );
    },
    getCurrentHash() {
      this.storedHash = window.location.hash;
    },
    scrollToHash(hash) {
      location.hash = hash;
    },
  },
};
</script>
