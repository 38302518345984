/* IE11 forEach polyfill */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (document.readyState !== "loading") {
  myInitCode();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    myInitCode();
  });
}

function myInitCode() {
  // Get all images on page
  const imgEl = document.querySelectorAll("img");

  // Replace data-src for src
  function replaceDataSrc(item) {
    if (item.getAttribute("data-src")) {
      item.setAttribute("src", item.getAttribute("data-src"));
      item.removeAttribute("data-src");
    }
  }

  // Replace :src for src
  function replaceColonSrc(item) {
    if (item.getAttribute(":src")) {
      item.setAttribute("src", item.getAttribute(":src"));
      item.removeAttribute(":src");
    }
  }

  // Replace src="'url'" for src="url"
  function replaceDoubleSrcQuotes(item) {
    if (item.getAttribute("src")) {
      item.getAttribute("src").replace(/['"]+/g, "");
      item.setAttribute("src", item.getAttribute("src").replace(/['"]+/g, ""));
    }
  }

  // Loop through images and check states
  for (let i = 0; i < imgEl.length; i++) {
    replaceDataSrc(imgEl[i]);
    replaceColonSrc(imgEl[i]);
    replaceDoubleSrcQuotes(imgEl[i]);
  }
}
