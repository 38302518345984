<template>
  <div class="savings-product savings-card savings-block">
    <h3 class="savings-card__title savings-card__title--product">
      {{ title }}
    </h3>
    <div class="savings-card__content product-info">
      <div class="product-info__content">
        <div class="product-info__product">
          <div class="product-image">
            <img :src="product.image" />
          </div>
          <h4 class="title">
            {{ product.sub_type }}
          </h4>
        </div>

        <div class="product-info__footer">
          <p>investering vanaf *</p>
          <p class="price">€ {{ product.price }},-</p>
        </div>
      </div>

      <SavingsGraph
        :percentage="product.return_on_investment_percentage"
        :value="`${return_on_investment_in_years} jaar`"
        copy="verwachte terugverdientijd *"
        class="product-info__graph"
      />
    </div>
  </div>
</template>

<script>
import SavingsGraph from "./SavingsGraph.vue";

export default {
  name: "SavingsProduct",
  components: { SavingsGraph },
  props: {
    title: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  computed: {
    return_on_investment_in_years() {
      const investement = this.product.return_on_investment_in_years;
      if (!investement) return null;
      return investement.toString().replace(".", ",");
    },
  },
};
</script>
