<template>
  <button class="cta btn" :class="classes" @click="emitBtnClick()">
    <span v-html="text"></span>
  </button>
</template>

<script>
export default {
  name: "UiButton",
  props: {
    classes: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: "Lees meer",
    },
  },
  methods: {
    emitBtnClick() {
      this.$emit("btnClick");
    },
  },
};
</script>
