<template><span></span></template>
<script>
export default {
  name: "NeoFormSubmissions",
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  computed: {
    formData() {
      return JSON.parse(this.data);
    },
  },
  mounted() {
    // Send custom event to GTM
    this.$gtm.trackEvent({
      event: "form-submit",
      eventCategory: "Form Submission",
      eventAction: `${
        this.formData && this.formData.status === "Failed"
          ? "Failed"
          : "Successful"
      }`,
      eventLabel: this.formData.name,
    });
  },
};
</script>
