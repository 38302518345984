var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neo__content-block neo__tabs_outer"},[_c('div',{staticClass:"neo__tabs-tab-holder"},[_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:`tab__${index}`},[_c('button',{staticClass:"neo__tabs-tab-button",class:[
          { active: _vm.currentIndex === index },
          { 'active-left': _vm.currentIndex === index - 1 },
          { 'active-right': _vm.currentIndex === index + 1 },
        ],on:{"click":function($event){_vm.currentIndex = index}}},[_vm._v(" "+_vm._s(tab.title)+" ")])])}),_c('div',[_c('button',{staticClass:"neo__tabs-tab-button",class:{
          active: _vm.currentIndex === _vm.tabs.length,
          'active-left': _vm.currentIndex === _vm.tabs.length - 1,
        },on:{"click":function($event){_vm.currentIndex = _vm.tabs.length}}},[_vm._v(" Veelgestelde vragen ")])])],2),_c('div',{staticClass:"neo__tab"},[_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:`tab__${index}`},[_c('transition',{attrs:{"name":"fade"}},[(_vm.currentIndex === index)?_c('div',{staticClass:"neo__tab-content-holder"},_vm._l((tab.content),function(content,i){return _c('div',{key:`tab-content__${i}`,staticClass:"neo__tab-content"},[_c('div',{staticClass:"neo__tab-content-content",class:{ 'neo__tab-content-content-full': !content.media }},[(!!content.dateString)?_c('span',{staticClass:"neo__tab-content-date"},[_vm._v("Update: "+_vm._s(content.dateString))]):_vm._e(),_c('h3',{staticClass:"neo__tab-content-title",domProps:{"textContent":_vm._s(content.title)}}),_c('div',{staticClass:"neo__tab-content-text neo__wysiwyg wysiwyg",domProps:{"innerHTML":_vm._s(content.text)}}),_c('div',{staticClass:"neo__tab-content-ctas neo__cta-list"},_vm._l((content.ctas),function(cta,j){return _c('div',{key:`cta__${j}`,domProps:{"innerHTML":_vm._s(cta.html)}})}),0)]),(!!content.media)?[_c('div',{staticClass:"neo__tab-content-media",class:'neo__tab-content-media-' + content.media.type},[(content.media.type === 'image')?_c('div',{staticClass:"neo__tabs-image-holder"},[_c('NeoImage',{attrs:{"src":content.media.src,"alt":content.media.alt}})],1):(content.media.type === 'video')?_c('div',[_c('video',{attrs:{"async":"","defer":"","width":"320","height":"240","autoplay":""}},[_c('source',{attrs:{"src":content.media.src,"type":'video/' + content.media.format}}),_vm._v(" Your browser does not support the video tag. ")])]):(content.media.type === 'youtube')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.youtubeEscape(content.media.html))}}):_vm._e()])]:_vm._e()],2)}),0):_vm._e()])],1)}),_c('transition',{attrs:{"name":"fade"}},[(_vm.currentIndex === _vm.tabs.length)?_vm._t("default"):_vm._e()],2)],2),_c('div',{staticClass:"neo__tabs_background"})])
}
var staticRenderFns = []

export { render, staticRenderFns }