<template>
  <LMap
    v-if="results"
    :center="center"
    :zoom="zoom"
    style="height: 100%; width: 100%"
    @update:bounds="boundsUpdated"
    @update:center="centerUpdated"
    @update:zoom="zoomUpdated"
  >
    <LTileLayer :url="url"></LTileLayer>
    <LMarker
      v-for="(establishment, index) in results.slice().reverse()"
      :key="index"
      :lat-lng="[establishment.latitude, establishment.longitude]"
    >
      <LIcon :icon-size="[40]">
        <svg
          aria-hidden="true"
          style="position: relative; top: -53px"
          focusable="false"
          data-prefix="fad"
          data-icon="map-marker"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            class="fa-secondary"
            :fill="fillColor"
            d="M384 192c0 77.41-27 99-172.27 309.67a24 24 0 0 1-39.46 0C27 291 0 269.41 0 192 0 86 86 0 192 0s192 86 192 192z"
            opacity="1"
          ></path>
          <text
            font-size="182"
            x="50%"
            y="232"
            font-weight="bold"
            dominant-baseline="middle"
            text-anchor="middle"
            :fill="fillColorText"
          >
            {{ getLetter(results.length - index - 1) }}
          </text>
        </svg>
      </LIcon>
      <LTooltip>
        <div class="tooltip-content">
          <span class="tooltip-counter">{{
            getLetter(results.length - index - 1, true)
          }}</span>
          <span class="seperator">|</span>
          <strong>{{ establishment.name }}</strong>
        </div>
      </LTooltip>
    </LMarker>
  </LMap>
</template>

<script>
/* eslint-disable import/no-commonjs */
import { Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon, LTooltip } from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;
const svg =
  '<svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="map-marker" class="svg-inline--fa fa-map-marker fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M384 192c0 77.41-27 99-172.27 309.67a24 24 0 0 1-39.46 0C27 291 0 269.41 0 192 0 86 86 0 192 0s192 86 192 192z" opacity="0.4"></path><path class="fa-primary" fill="currentColor" d=""></path></g></svg>';
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: btoa(svg)
    ? "data:image/svg+xml;base64," + btoa(svg)
    : require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "NeoLeafletMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: this.form.search === "Nederland" ? 7 : 11,
      center: [this.results[0].latitude, this.results[0].longitude],
      bounds: null,
      fillColor: "#E20015",
      fillColorText: "#FFF",
    };
  },
  methods: {
    getLetter(index, showIndex = false) {
      const alphabet = String.fromCharCode(...Array(123).keys())
        .slice(97)
        .split("");
      if (index < alphabet.length) {
        return alphabet[index].toUpperCase();
      } else if (index < alphabet.length * alphabet.length) {
        return (
          alphabet[Math.floor(index / alphabet.length - 1)].toUpperCase() +
          alphabet[
            index - alphabet.length * Math.floor(index / alphabet.length)
          ].toUpperCase()
        );
      } else if (showIndex) {
        return index;
      }
      return "";
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
  },
};
</script>
