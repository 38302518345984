<template>
  <div class="neo_chart-container">
    <h5 v-if="contentData.chartTitle" class="neo_chart-title">
      <span v-html="contentData.chartTitle"></span>
    </h5>
    <DoughnutChart
      v-if="filterDone && datasets[0].data.length > 0"
      :datasets="datasets"
      :labels="cleanedLabels"
      :options="{ legend: { display: false } }"
      class="neo_chart"
    />
    <div id="chart-legends" class="neo_chart-legends"></div>
  </div>
</template>

<script>
import DoughnutChart from "./DoughnutChart.vue";

export default {
  name: "NeoChart",
  components: {
    DoughnutChart,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      labels: [],
      datasets: [
        {
          label: "Data sets",
          data: [],
          backgroundColor: [],
          borderWidth: 4,
        },
      ],
      data: [],
      filterDone: false,
    };
  },
  computed: {
    contentData() {
      return JSON.parse(this.content);
    },
    cleanedLabels() {
      return this.labels.map((e) =>
        e.replace('<span class="pro-label">Pro</span>', "")
      );
    },
  },
  mounted() {
    this.fillDataArray();
  },
  methods: {
    fillDataArray() {
      for (let i = 0; i < this.contentData.chart.length; i++) {
        this.labels.push(this.contentData.chart[i].chartPieceLabel);
        this.datasets[0].data.push(this.contentData.chart[i].chartPieceValue);
        this.datasets[0].backgroundColor.push(
          this.contentData.chart[i].chartPieceColor
        );
      }
      this.filterDone = true;
    },
  },
};
</script>
