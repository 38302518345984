<template>
  <div class="neo__grid-container neo__article-list-block__outer">
    <div class="neo__content-block">
      <div class="neo__article-list-block__blog-navigator__outer">
        <div class="neo__article-list-block__blog-navigator__title">
          <h3>Nieuws totaal overzicht</h3>
        </div>
        <div class="neo__article-list-block__blog-navigator__selector">
          <button
            class="neo__article-list-block__blog-navigator__date-toggle"
            @click="toggleDateOrder()"
          >
            <span>
              Toon op datum
              <div
                class="neo-epsilon neo-epsilon-blue"
                :class="[{ active: dateOrder !== 'desc' }]"
              ></div>
            </span>
          </button>
        </div>
      </div>
      <div class="neo__article-list-block-items__outer">
        <a
          v-for="(item, index) in filterdItems"
          :key="`items__${index}`"
          :href="item.url"
          class="neo__article-list-block-item__outer"
        >
          <NeoImage
            v-if="item.image && item.image.url"
            :class="'neo__article-list-block-item__preview'"
            :src="item.image.url"
            :width="item.image.width ? item.image.width : null"
            :height="item.image.height ? item.image.height : null"
            :alt="item.title"
          />
          <div class="neo__article-list-block-item__content">
            <h4 class="neo__article-list-block-item__title">
              {{ item.title }}
            </h4>
            <div
              class="neo__article-list-block-item__boilerplate"
              v-html="item.description ? item.description : item.boilerplate"
            ></div>
            <p class="neo__article-list-block-item__date">
              {{ getDate(item.date) }}
            </p>
          </div>
        </a>
      </div>
      <div class="neo__article-list-block-items__loadmore">
        <button
          v-if="structuredNewsItems.length <= maxIndex === false"
          class="neo__article-list-block-items__loadmore-text neo__cta btn"
          @click="loadMore"
        >
          Toon meer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NeoImage from "./NeoImage.vue";
export default {
  name: "NeoArticleListBlock",
  components: {
    NeoImage,
  },
  props: {
    newsData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      maxIndex: 8,
      dateOrder: "desc",
    };
  },
  computed: {
    parsedNewsData() {
      return JSON.parse(this.newsData);
    },
    structuredNewsItems() {
      let dataContainer = this.parsedNewsData.map((item) => {
        return item;
      });
      if (this.dateOrder && this.dateOrder !== "desc") {
        dataContainer = dataContainer.reverse();
      }
      return dataContainer;
    },
    filterdItems() {
      return this.structuredNewsItems.slice(0, this.maxIndex);
    },
  },
  methods: {
    loadMore() {
      this.maxIndex += 5;
    },
    toggleDateOrder() {
      if (this.dateOrder === "desc") {
        this.dateOrder = "asc";
      } else {
        this.dateOrder = "desc";
      }
    },
    getDate(date) {
      //possible date refactor function here

      return date;
    },
  },
};
</script>
