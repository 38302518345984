<template>
  <div class="savings-exception container exception">
    <svg
      width="129"
      height="157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.287.324.5 63.621v92.628h127.575V63.621L64.288.324ZM48.341 148.337v-47.473h31.893v47.473H48.341Zm71.761 0H88.208V92.952h-47.84v55.385H8.472V66.899l55.814-55.385 55.815 55.385v81.438Z"
        fill="url(#a)"
      />
      <defs>
        <linearGradient
          id="a"
          x1="-13.5"
          y1="78"
          x2="128"
          y2="78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#50237F" />
          <stop offset=".968" stop-color="#005691" />
        </linearGradient>
      </defs>
    </svg>
    <h3 class="title">{{ title }}</h3>
    <div class="redactor" v-html="text"></div>
    <a
      v-if="cta"
      :href="cta.url"
      :target="cta.target"
      class="cta link icon--next btn"
      >{{ cta.text }}</a
    >
  </div>
</template>

<script>
export default {
  name: "SavingsException",
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    cta: {
      type: Object,
      default: null,
    },
  },
};
</script>
