<template>
  <div class="savings-footnote savings-block">
    <div class="redactor" v-html="footnote"></div>
  </div>
</template>

<script>
export default {
  name: "SavingsFootnote",
  props: {
    footnote: {
      type: String,
      default: null,
    },
  },
};
</script>
