<template>
  <carousel
    :per-page="1"
    :pagination-enabled="false"
    :mouse-drag="true"
    :touch-drag="true"
    :navigation-enabled="true"
    navigation-next-label="<div class='neo-epsilon neo-epsilon-gray'></div>"
    navigation-prev-label="<div class='neo-epsilon neo-epsilon-gray neo-epsilon-left'></div>"
    :navigation-click-target-size="36"
  >
    <slide
      v-for="(item, index) in quotes"
      :key="index"
      class="block__partial--slider__item"
    >
      <div class="block__partial--slider__item__inner">
        <div
          class="block__partial--slider__item__content wysiwyg"
          v-html="item.text"
        ></div>
        <div class="block__partial--slider__item__author">
          <span
            class="block__partial--slider__item__author--name"
            v-html="item.author"
          ></span>
        </div>
      </div>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "PartialsQuoteSlider",
  components: {
    carousel: Carousel,
    slide: Slide,
  },
  props: {
    quotes: {
      type: Array,
      required: true,
    },
  },
};
</script>
