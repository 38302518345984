/* IE11 forEach polyfill */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (document.readyState !== "loading") {
  myInitCode();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    myInitCode();
  });
}

function myInitCode() {
  const formErrors = document.querySelector("#formErrors");
  const formSuccess = document.querySelector("#formSuccess");

  if (formErrors) {
    const errorScrollPosition = formErrors.pageYOffset;
    window.scrollTo(0, errorScrollPosition);
  } else if (formSuccess) {
    const successScrollPosition = formSuccess.pageYOffset;
    window.scrollTo(0, successScrollPosition);
  }
}
