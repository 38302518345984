<template>
  <div class="container">
    <div
      id="tt_dealersearch"
      data-brand="nefit"
      data-country="nl"
      data-lng="nl-NL"
      class="c-dealersearch"
    >
      <iframe
        src="https://dealersearch.thernovotools.com/nefit/nl/dealersearch?lng=nl-NL"
        allow="clipboard-write"
        scrolling="no"
        aria-label="integrated bosch homecomfort tool"
        style="width: 100%; height: 1200px; border: none"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartialsDealerLocator",
};
</script>

<style scoped lang="scss">
.c-dealersearch {
  grid-column: 2/ -2;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  iframe {
    width: 100%;
  }
}
</style>
