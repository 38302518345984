<template>
  <div class="image__container" :class="customClass">
    <img
      class="image"
      :class="load"
      :style="imageStyle"
      :width="width ? width : null"
      :height="height ? height : null"
      :alt="alt"
      :aria-label="alt"
      :data-src="src"
    />
  </div>
</template>

<script>
export default {
  name: "UiImage",
  props: {
    load: {
      type: String,
      default: "lozad",
    },
    customClass: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: "Nefit Bosch",
    },
    src: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    imageStyle: {
      type: String,
      default: "background-position: 50% 50%; object-position: 50% 50%;",
    },
  },
  mounted() {
    this.$lozad.observe();
  },
};
</script>
