<template>
  <header class="header__outer" :class="{ open: menuOpen }">
    <div v-if="menuOpen" class="control--close" @click="toggleMenu()"></div>

    <slot name="brandbar"></slot>

    <div class="header__inner">
      <div v-if="data" class="header__menu">
        <div class="header__top-container">
          <slot name="logo"></slot>

          <div class="header__controls">
            <slot name="search"></slot>

            <button
              class="header__toggle"
              :class="{ active: menuOpen }"
              @click="toggleMenu()"
            >
              <span class="header__toggle-bar"></span>
              <span class="header__toggle-bar"></span>
              <span class="header__toggle-bar"></span>
            </button>
          </div>
        </div>

        <div class="header__navlist" :class="{ open: menuOpen }">
          <ul
            class="header__navlist__list"
            :class="{ active: level2mobile === 2 }"
          >
            <li
              v-for="(level1, index) in menu"
              :id="level1.title"
              :key="`menu-level-1__item-${index}`"
              class="level1"
              :class="{ active: activeMenuIndex === index }"
            >
              <a
                v-if="level1.url"
                :href="level1.url"
                :target="level1.target"
                :alt="level1.title"
                class="neo__cta link"
                @click="toggleMenu()"
              >
                <span v-html="level1.title"></span>
              </a>

              <button
                v-else
                class="header__navlist__button"
                @click="
                  setActiveMenuIndex(index, level1.children, level1.title)
                "
              >
                <span
                  class="header__navlist__button__label neo__cta"
                  :class="{ active: activeMenuIndex === index }"
                >
                  <span v-html="level1.title"></span>
                </span>
              </button>
            </li>
          </ul>

          <ul
            class="header__navlist__mobile-topmenu"
            :class="{ active: level2mobile === 2 }"
          >
            <li
              v-for="(level1, index) in topmenu"
              :id="level1.title"
              :key="`topmenu-level-1__item-${index}`"
              class="topmenu-level1"
            >
              <a
                :href="level1.url"
                :target="level1.target"
                :alt="level1.title"
                class="neo__cta link"
                @click="toggleMenu()"
              >
                <span v-html="level1.title"></span>
              </a>
            </li>
          </ul>

          <slot name="search"></slot>
        </div>

        <div
          v-if="activeMenuIndex > -1"
          class="header__level2"
          :class="{ active: activeMenuIndex > -1 }"
        >
          <div
            class="header__level2__sidebar"
            :class="{ active: level3mobile === 2 }"
          >
            <button class="back__button" @click="backToLevel2()">
              <slot name="arrow"></slot>
              <span v-html="selectedLevel3"></span>
            </button>

            <ul class="sidebar__navlist">
              <li
                v-for="(level2, index) in level1children"
                :id="level2.title"
                :key="`menu-level-2__item-${index}`"
                class="level2"
                :class="{ active: activeSubMenuIndex === index }"
              >
                <button
                  v-if="level2.columns.length >= 1"
                  class="header__navlist__button"
                  @click="
                    setActiveSubMenuIndex(
                      index,
                      level2.columns,
                      level2.directLinks,
                      level2.title
                    )
                  "
                >
                  <span
                    class="header__navlist__button__label neo__cta"
                    v-html="level2.title"
                  ></span>
                  <slot name="arrow"></slot>
                </button>

                <a
                  v-else
                  :href="level2.url"
                  :target="level2.target"
                  :alt="level2.title"
                  class="neo__cta link"
                  @click="toggleMenu()"
                >
                  <span v-html="level2.title"></span>
                </a>
              </li>
            </ul>
          </div>
          <div class="header__level2__links">
            <!-- desktop columns level 3 -->
            <ul class="links__columns">
              <li
                v-for="(level3column, index) in level2columns"
                :id="level3column.title"
                :key="`menu-level-3-column__item-${index}`"
                class="level3__column"
              >
                <div class="level3__column__title">
                  <span v-html="level3column.title"></span>
                </div>
                <ul class="level3__column__ctas">
                  <li
                    v-for="(level4cta, i) in level3column.ctas"
                    :id="level4cta.title"
                    :key="`menu-level-4-cta__item-${i}`"
                    class="level4__cta"
                  >
                    <a
                      :href="level4cta.url"
                      :target="level4cta.target"
                      :alt="level4cta.title"
                      @click="toggleMenu()"
                    >
                      <span
                        class="level4__cta__text"
                        v-html="level4cta.title"
                      ></span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="header__level2__menu--toggle">
              <button @click="toggleMenu()">
                <slot name="close"></slot>
              </button>
            </div>

            <!-- mobile "columns" level 4 -->
            <div
              class="links__mobile__columns"
              :class="{ active: level4mobile === 2 }"
            >
              <button class="back__button" @click="backToLevel3()">
                <slot name="arrow"></slot>
                <span v-html="selectedLevel4"></span>
              </button>

              <ul>
                <li
                  v-for="(level4column, index) in level2columns"
                  :id="level4column.title"
                  :key="`menu-level-4-column__item-${index}`"
                  class="level4__column"
                >
                  <button
                    class="level4__column__button"
                    @click="
                      setActiveSecondSubMenuIndex(
                        level4column.title,
                        level4column.ctas
                      )
                    "
                  >
                    <span v-html="level4column.title"></span>
                  </button>
                </li>
              </ul>
            </div>

            <!-- mobile "columns" level 5 -->
            <div
              class="links__mobile__ctas"
              :class="{ active: level5mobile === 2 }"
            >
              <button class="back__button" @click="backToLevel4()">
                <slot name="arrow"></slot>
                <span v-html="selectedLevel5"></span>
              </button>

              <ul>
                <li
                  v-for="(level5cta, index) in level5ctas"
                  :id="level5cta.title"
                  :key="`menu-level-5-cta__item-${index}`"
                  class="level5__cta"
                >
                  <a
                    :href="level5cta.url"
                    :target="level5cta.target"
                    :alt="level5cta.title"
                    @click="toggleMenu()"
                  >
                    <span v-html="level5cta.title"></span>
                  </a>
                </li>
              </ul>
            </div>

            <!-- directlinks level 3 desktop & level 4 mobile -->
            <ul
              v-if="level2directlinks.length > 0"
              class="links__directlinks"
              :class="{ active: level4mobile === 2 }"
            >
              <li
                v-for="(level3directlink, index) in level2directlinks"
                :id="level3directlink.title"
                :key="`menu-level-3-directlink__item-${index}`"
                class="level3__directlink"
              >
                <a
                  :href="level3directlink.url"
                  :target="level3directlink.target"
                  :alt="level3directlink.title"
                  class="neo__cta link"
                  @click="toggleMenu()"
                >
                  <span v-html="level3directlink.title"></span>
                  <slot name="arrow"></slot>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
export default {
  name: "HeaderMenu",
  props: {
    data: {
      type: String,
      required: true,
    },
    top: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menuOpen: false,
      level2mobile: -1,
      level3mobile: -1,
      level4mobile: -1,
      level5mobile: -1,
      activeMenuIndex: -1,
      activeSubMenuIndex: -1,
      level1children: [],
      level2columns: [],
      level2directlinks: [],
      level5ctas: [],
      selectedLevel3: "",
      selectedLevel4: "",
      selectedLevel5: "",
    };
  },
  computed: {
    menu() {
      return JSON.parse(this.data);
    },
    topmenu() {
      return JSON.parse(this.top);
    },
  },
  methods: {
    setActiveMenuIndex(e, level1children, selectedTitle) {
      // set data
      this.level1children = level1children;
      this.level2columns = level1children[0].columns;
      this.level2directlinks = level1children[0].directLinks;
      // desktop settings (if the current button is clicked, then hide the menu, otherwise set it to the current index to open)
      this.activeMenuIndex = e === this.activeMenuIndex ? -1 : e;
      this.activeSubMenuIndex = 0;
      // mobile settings
      this.level2mobile = 1;
      this.level3mobile = 2;
      this.menuOpen = true;
      this.selectedLevel3 = selectedTitle;
    },
    setActiveSubMenuIndex(e, level2columns, level2directlinks, selectedTitle) {
      //set data
      this.level2columns = level2columns;
      this.level2directlinks = level2directlinks;
      // desktop settings
      this.activeSubMenuIndex = e === this.activeSubMenuIndex ? 0 : e;
      // mobile settings
      this.level3mobile = 1;
      this.level4mobile = 2;
      this.selectedLevel4 = selectedTitle;
    },
    setActiveSecondSubMenuIndex(selectedTitle, ctas) {
      // set data
      this.level5ctas = ctas;
      // mobile settigns
      this.level4mobile = 1;
      this.level5mobile = 2;
      this.selectedLevel5 = selectedTitle;
    },
    toggleMenu() {
      // mobile settings
      // -1 = closed
      // 1 = hidden but menu is still open
      // 2 = visible
      this.menuOpen = !this.menuOpen;
      this.level2mobile = this.level2mobile > 0 ? -1 : 2;
      this.level3mobile = this.level3mobile > 0 ? -1 : 1;
      this.level4mobile = this.level4mobile > 0 ? -1 : 1;
      this.level5mobile = this.level5mobile > 0 ? -1 : 1;
      this.selectedLevel3 = "";
      this.selectedLevel4 = "";
      this.selectedLevel5 = "";
      // desktop settings
      this.activeMenuIndex = -1;
    },
    backToLevel2() {
      this.level2mobile = 2;
      this.level3mobile = 1;
      this.selectedLevel3 = "";
      // desktop settings
      this.activeMenuIndex = -1;
    },
    backToLevel3() {
      this.level3mobile = 2;
      this.level4mobile = 1;
      this.selectedLevel4 = "";
    },
    backToLevel4() {
      this.level4mobile = 2;
      this.level5mobile = 1;
      this.selectedLevel5 = "";
    },
  },
};
</script>
