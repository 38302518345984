<template>
  <div class="partial__block partial__locator" :class="classes">
    <!-- Props -->
    <div class="block--content">
      <h3 v-if="neo_title" class="block__partial--title">
        <span v-html="neo_title"></span>
      </h3>
      <div
        v-if="neo_description"
        class="block__partial--description wysiwyg"
        v-html="neo_description"
      ></div>

      <form
        class="block__partial--form locator__form"
        @submit.prevent="submitLocation"
      >
        <input
          v-model="neo__locatorInput"
          class="locator__input form--input"
          placeholder="Jouw postcode"
          type="text"
        />
        <UiButton
          :text="neo_btn"
          classes="locator__button form--button icon--search"
          @click.prevent="submitLocation"
        ></UiButton>
      </form>
    </div>

    <div class="block--image">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import UiButton from "./UiButton.vue";

export default {
  name: "PartialsLocatorBlock",
  components: {
    UiButton,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      neo__locatorInput: "",
    };
  },
  computed: {
    neo_title() {
      return this.title;
    },
    neo_description() {
      return this.description;
    },
    neo_image() {
      return this.image;
    },
    neo_btn() {
      return this.search;
    },
  },
  methods: {
    submitLocation() {
      window.location =
        "/consument/vind-een-installateur?place=" + this.neo__locatorInput;
    },
  },
};
</script>
