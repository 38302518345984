var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quizTool"},[_c('div',{staticClass:"quizTool__header"},[_c('div',{staticClass:"top-border"}),_c('div',{staticClass:"quizTool__header-content"},[_vm._t("logo"),_c('div',{staticClass:"button-controls"},[_c('a',{staticClass:"questions",attrs:{"href":`${_vm.siteUrl}/klantenservice/#vragen`}},[_c('span',{staticClass:"questions"},[_vm._v("Vragen?")]),_c('span',{staticClass:"icon"},[_vm._v("?")])]),_c('a',{staticClass:"closeAdviceTool",attrs:{"href":_vm.closeUrl}},[_vm._m(0)])])],2)]),_c('div',{staticClass:"quizTool__progressbar",style:(`width: calc((${_vm.progress} / ${_vm.parsedQuestions.length}) * 100% + 20px)`)}),_c('div',{staticClass:"quizTool__content"},[_vm._l((_vm.parsedQuestions),function(item,itemIndex){return _c('div',{key:itemIndex,staticClass:"question__container",class:[
        { show: itemIndex === _vm.currentQuestion },
        { hide: itemIndex !== _vm.currentQuestion },
      ]},[_c('h3',{staticClass:"question__title"},[_vm._v(_vm._s(item.question))]),_c('p',{staticClass:"question__description"},[_vm._v(_vm._s(item.description))]),_c('p',{staticClass:"question__progress-text"},[_vm._v(" Vraag "),_c('span',[_vm._v(_vm._s(_vm.progress + 1))]),_vm._v(" van "+_vm._s(_vm.parsedQuestions.length)+" ")]),_c('div',{staticClass:"answer_container"},_vm._l((item.answers),function(answer,index){return _c('div',{key:index,staticClass:"answer__block",class:[{ active: _vm.userChoices[itemIndex] === _vm.getLetter(index) }],on:{"click":function($event){_vm.setUserChoice(itemIndex, _vm.getLetter(index))}}},[_c('div',{staticClass:"answer__indicator"},[_c('span',[_vm._v(_vm._s(_vm.getLetter(index)))])]),_c('div',{staticClass:"answer__text"},[_vm._v(_vm._s(answer))])])}),0)])}),_c('div',{staticClass:"quizControls",class:{
        hide:
          _vm.currentQuestion === _vm.parsedQuestions.length &&
          _vm.parsedAnswers[_vm.matchingResultIndex],
      }},[(_vm.currentQuestion > 0)?_c('button',{staticClass:"cta btn icon--back",on:{"click":function($event){return _vm.goBack()}}},[_c('span',[_vm._v("Vorige vraag")])]):_vm._e()])],2),(
      _vm.currentQuestion === _vm.parsedQuestions.length &&
      _vm.parsedAnswers[_vm.matchingResultIndex]
    )?_c('div',{staticClass:"quizTool__results"},[_c('div',{staticClass:"quizTool__calculatedResult"},[_c('div',{staticClass:"quizTool__result-content"},[_c('div',{staticClass:"quizTool__back-container"},[_c('button',{staticClass:"cta icon--backblack",on:{"click":function($event){return _vm.goBack()}}},[_c('span',[_vm._v("Vorige vraag")])])]),(_vm.parsedAnswers[_vm.matchingResultIndex])?_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.parsedAnswers[_vm.matchingResultIndex].title)+" ")]):_vm._e(),_c('h5',{staticClass:"heading"},[_vm._v("Ons advies")]),_c('div',{staticClass:"description"},[_c('div',{staticClass:"regular-text"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.parsedAnswers[_vm.matchingResultIndex].description)}})]),_c('div',{staticClass:"cta-container"},[_c('a',{staticClass:"cta btn icon--next",attrs:{"href":_vm.parsedAnswers[_vm.matchingResultIndex].cta}},[_vm._v("Bekijk meer")]),_c('a',{attrs:{"href":_vm.parsedAnswers[_vm.matchingResultIndex].quotationLink}},[_vm._v("Of vraag een offerte aan")])])])]),_c('div',{staticClass:"product-container"},[_c('img',{staticClass:"prduct-image",attrs:{"src":_vm.parsedAnswers[_vm.matchingResultIndex].imageUrl,"alt":_vm.parsedAnswers[_vm.matchingResultIndex].imageAlt}})])]),_c('section',{staticClass:"quizTool__alternative"},[_vm._m(1),_c('div',{staticClass:"alternative-container"},_vm._l((_vm.parsedAnswers[_vm.matchingResultIndex]
            .alternative),function(item,index){return _c('div',{key:index,staticClass:"alternative-card"},[_c('div',{staticClass:"heading"},[_c('img',{staticClass:"image",attrs:{"src":item.imageUrl,"alt":item.imageAlt}})]),_c('div',{staticClass:"body"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(item.description)}}),_c('a',{staticClass:"cta btn icon--next button",attrs:{"href":item.link}},[_vm._v("Bekijken")])])])}),0)]),_c('section',{staticClass:"block quizTool__footer"},[_c('div',{staticClass:"quizTool__footer-block"},[_c('div',{staticClass:"quizTool__footer-block-content"},[(_vm.parsedOfferData.ctaBlockTitle)?_c('h3',{staticClass:"quizTool__footer-block-title"},[_vm._v(" "+_vm._s(_vm.parsedOfferData.ctaBlockTitle)+" ")]):_vm._e(),(_vm.parsedOfferData.ctaBlockBody)?_c('div',{staticClass:"quizTool__footer-block-body wysiwyg"},[_c('p',[_vm._v(_vm._s(_vm.parsedOfferData.ctaBlockBody))])]):_vm._e(),(
              _vm.offerteLink &&
              _vm.parsedOfferData.ctaButtonLink &&
              _vm.parsedOfferData.ctaButtonLink.text
            )?_c('a',{staticClass:"cta btn icon--next",attrs:{"href":_vm.parsedAnswers[_vm.matchingResultIndex].quotationLink,"aria-label":_vm.parsedOfferData.ctaButtonLink.text}},[_c('span',[_vm._v(_vm._s(_vm.parsedOfferData.ctaButtonLink.text))])]):(
              _vm.parsedOfferData.ctaButtonLink &&
              _vm.parsedOfferData.ctaButtonLink.text
            )?_c('a',{staticClass:"cta btn icon--next",attrs:{"href":_vm.offerteLink,"aria-label":_vm.parsedOfferData.ctaButtonLink.text}},[_c('span',[_vm._v(_vm._s(_vm.parsedOfferData.ctaButtonLink.text))])]):_vm._e()]),_c('div',{staticClass:"quizTool__footer-block-image"},[_c('img',{staticClass:"image lozad",attrs:{"alt":"advise support image","aria-label":"advise support image","src":_vm.parsedOfferData.ctaBlockImage[0].url,"data-loaded":"true"}})])]),_c('PartialsLocatorBlock',{attrs:{"classes":"classes","title":_vm.parsedLocatorData.locatorBlockTitle,"image":_vm.parsedLocatorData.locatorBlockImage[0].url}})],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"description"},[_c('img',{attrs:{"src":require("../assets/img/icons/close.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-container"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Onze andere opties")])])
}]

export { render, staticRenderFns }