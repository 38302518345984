<template>
  <div class="test-slot">
    <slot name="test"></slot>
  </div>
</template>

<script>
export default {
  name: "TestClick",
};
</script>
