import Vue from "vue";
import kebabCase from "lodash/kebabCase";

import ScrollTrigger from "scrolltrigger-classes";
import lozad from "lozad";
import VueGtm from "vue-gtm";

Vue.config.productionTip = false;

Vue.use(VueGtm, {
  id: "GTM-PHPHB7L",
  loadScript: true,
});

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

Object.defineProperty(Vue.prototype, "$lozad", { value: observer });

if (document.readyState !== "loading") {
  myInitCode();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    myInitCode();
  });
}

function myInitCode() {
  const trigger = new ScrollTrigger(
    {
      toggle: {
        visible: "animation--active",
        hidden: "animation--inactive",
      },
      offset: {
        x: 0,
        y: -200,
      },
      addHeight: true,
      once: true,
    },
    document.body,
    window
  );
  trigger.bind(document.querySelectorAll("[data-scroll]"));
}

Vue.config.productionTip = false;

const requireComponent = require.context("./", true, /[A-Z]\w+\.(vue)$/);
const selectors = [];
requireComponent.keys().forEach((filename) => {
  // Register components
  const component = requireComponent(filename).default;
  component.name = component.name || filename.match("([^/]+).vue$")[1];
  component.delimiters = component.delimiters || ["${", "}"];
  const elementName = kebabCase(component.name);
  if (elementName.indexOf("-") === -1) {
    console.warn(`element name must contain a dash, rename "${elementName}"`); // eslint-disable-line no-console
    return;
  }
  Vue.component(elementName, component);
  selectors.push(elementName);
});
const detectedElements = [];
/* eslint-disable no-unused-vars */
for (const selector of selectors) {
  detectedElements.push(...document.querySelectorAll(selector));
}
// Mount components

for (const selectorX of selectors) {
  for (const el of document.querySelectorAll(selectorX)) {
    let parent = el.parentNode;
    let mount = true;
    while (parent) {
      if (detectedElements.indexOf(parent) !== -1) {
        mount = false; // this element will be mounted as subcomponent
        break;
      }
      parent = parent.parentNode;
    }
    if (mount) {
      new Vue({ el }); // eslint-disable-line no-new
    }
  }
}
