var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.parsedProducts && _vm.parsedProducts.length)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"neo__content-block neo__related-products-block__outer"},[(_vm.title.length)?_c('h3',{staticClass:"h4 neo__related-products-block__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('carousel',{staticClass:"neo__related-products-block__inner",attrs:{"navigation-enabled":true,"navigation-next-label":"<div class='neo-epsilon neo-epsilon-blue'></div>","navigation-prev-label":"<div class='neo-epsilon neo-epsilon-blue neo-epsilon-left'></div>","navigation-click-target-size":36,"pagination-enabled":false,"per-page-custom":[
        // shows `auto` items below 568 pixels
        [524, Math.min(_vm.parsedProducts.length, 3)], //shows 3 items from 524 pixels
        [768, Math.min(_vm.parsedProducts.length, 4)], //shows 4 items from 768 pixels
        [992, Math.min(_vm.parsedProducts.length, 5)], // shows 5 items above 992 pixels
        [1024, Math.min(_vm.parsedProducts.length, 5)], // shows 5 items above 1024 pixels
        [1400, Math.min(_vm.parsedProducts.length, 6)], // shows 6 items above 1400 pixels
        [1600, Math.min(_vm.parsedProducts.length, 6)], // shows 6 items above 1600 pixels
      ],"scroll-per-page":true}},_vm._l((_vm.parsedProducts),function(product,index){return _c('slide',{key:`product__${index}`,staticClass:"neo_related-product"},[_c('a',{attrs:{"href":product.url}},[_c('div',{staticClass:"neo_related_image-holder"},[_c('NeoImage',{attrs:{"src":product.imageSrc,"alt":'Gerelateerd product'}})],1),_c('p',{staticClass:"neo__cta link"},[_vm._v(_vm._s(product.title))])])])}),1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }