<template>
  <div class="savings-installer">
    <a
      v-if="cta"
      :href="cta.url"
      :target="cta.target"
      class="cta link icon--next btn"
      >{{ cta.text }}</a
    >
    <a href="/consument/vind-een-installateur" class="link link--installer"
      ><i class="icon icon--fitter"></i>Vind een installateur</a
    >
  </div>
</template>

<script>
export default {
  name: "SavingsInstaller",
  props: {
    cta: {
      type: Object,
      default: null,
    },
    footnote: {
      type: String,
      default: null,
    },
  },
};
</script>
