<template>
  <img
    :src="image"
    :srcset="srcsetWebp"
    :sizes="sizes"
    :alt="title"
    :title="title"
    :loading="loading"
    decoding="async"
    :class="classesImage"
    :style="{
      objectPosition: `${focalPoint.x * 100}% ${focalPoint.y * 100}%`,
    }"
  />
</template>

<script>
export default {
  name: "ImageComponent",
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    sizes: {
      type: String,
      default: "100vw",
    },
    classesImage: {
      type: String,
      default: "",
    },
    loading: {
      type: String,
      default: "lazy",
    },
    srcsetWebp: {
      type: String,
      default: "",
    },
    focalPoint: {
      type: Object,
      default: () => ({
        x: 0.5,
        y: 0.5,
      }),
    },
  },
};
</script>
