var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neo__content-block neo__product-nav-block__outer"},[_c('a',{staticClass:"neo__cta link back",attrs:{"href":_vm.back}},[_vm._v("Terug")]),_c('carousel',{staticClass:"neo__product-nav-block__inner",attrs:{"navigation-enabled":true,"navigation-next-label":"","navigation-prev-label":"","navigation-click-target-size":36,"pagination-enabled":true,"per-page-custom":[
      // shows `auto` items below 568 pixels
      [524, Math.min(_vm.parsedProducts.length, 3)], //shows 3 items from 524 pixels
      [768, Math.min(_vm.parsedProducts.length, 4)], //shows 4 items from 768 pixels
      [992, Math.min(_vm.parsedProducts.length, 5)], // shows 5 items above 992 pixels
      [1024, Math.min(_vm.parsedProducts.length, 5)], // shows 5 items above 1024 pixels
      [1400, Math.min(_vm.parsedProducts.length, 5)], // shows 6 items above 1400 pixels
      [1600, Math.min(_vm.parsedProducts.length, 6)], // shows 6 items above 1600 pixels
    ],"scroll-per-page":true}},_vm._l((_vm.parsedProducts),function(product,index){return _c('slide',{key:`product-nav__${index}`,staticClass:"neo__product-nav-product"},[_c('a',{class:{ active: product.isActive },attrs:{"href":product.url}},[_c('div',{staticClass:"neo__product-nav-block-image-holder"},[_c('NeoImage',{attrs:{"src":product.imageSrc,"alt":'Product navigatie item'}})],1),_c('p',{staticClass:"neo__cta link"},[_vm._v(_vm._s(product.title))])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }