<template>
  <div class="neo__content-block neo__gas-usage-calculator__outer">
    <h2 class="h3 neo__gas-usage-calculator__title">
      Hoeveel bomen er nodig zijn om jouw gasverbruik te compenseren ?
    </h2>

    <div class="neo__gas-usage-calculator__main-content">
      <div class="neo__gas-usage-calculator__culculator__outer">
        <form
          class="neo__gas-usage-calculator__culculator__inner"
          @submit.prevent
        >
          <label class="form-input">
            <p>Mijn gasverbruik in het afgelopen jaar:</p>
            <input
              v-model="gasUse"
              class="input"
              type="number"
              placeholder="0"
            />
            <span class="extension">&#13221;</span>
          </label>

          <span class="divider"></span>

          <label class="form-answer">
            <p>De CO<sup>&#x2082;</sup>-uitstoot is:</p>
            <span class="input">{{ kgAmount }}</span>
            <span class="extension"> kg</span>
          </label>
          <label class="form-answer">
            <p>
              Het aantal bomen dat nodig is om deze CO<sup>&#x2082;</sup> uit de
              lucht te halen:
            </p>
            <span class="input">{{ treesNeeded }}</span>
            <button
              type="button"
              class="toggle-modal-btn"
              @click="toggleModal()"
            >
              <span class="toggle-modal-btn__text">{{
                modal ? "x" : "i"
              }}</span>
            </button>
          </label>
        </form>
        <div v-if="modal" class="modal-gas">
          <div class="modal-gas__inner">
            <p>
              Hoeveel CO<sup>&#x2082;</sup> een boom opneemt, hangt van veel
              variabelen af.<br />In onze CO<sup>&#x2082;</sup>-projecten is dit
              gemiddeld 200kg
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="neo__gas-usage-calculator__side-content">
      <div class="neo__gas-usage-calculator__image-container">
        <NeoImage
          :src="`${siteUrl}assets/neo/green-logo.png`"
          :alt="'green plant image'"
        >
        </NeoImage>
      </div>
    </div>
  </div>
</template>

<script>
import NeoImage from "./NeoImage.vue";

export default {
  name: "NeoGasUsageCalculator",
  components: {
    NeoImage,
  },
  props: {
    siteUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gasUse: 0,
      modal: false,
    };
  },
  computed: {
    realCo2() {
      // 1m3 -> 2kg
      return this.gasUse * 2;
    },
    //
    kgAmount() {
      return Math.ceil(this.realCo2);
    },
    // 200kg -> 1 boom
    treesNeeded() {
      return Math.ceil(this.realCo2 * 0.005);
    },
  },
  methods: {
    toggleModal() {
      this.modal = !this.modal;
    },
  },
};
</script>
