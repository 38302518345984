<template>
  <div class="neo__locator-block" :class="classes">
    <!-- Props -->
    <div class="neo__locator-block_content">
      <p class="h4 neo__locator-block__title">
        {{ neo_title }}
      </p>
      <div class="neo__locator-block__text">
        <div v-html="neo_description"></div>
      </div>

      <form class="neo__locator-block__form" @submit.prevent="submitLocation">
        <input
          v-model="neo__locatorInput"
          class="locator-block__input"
          placeholder="Jouw postcode"
          type="text"
        />
        <button
          aria-label="search"
          class="neo__locator-block__submit neo__cta btn cta__button"
          @click.prevent="submitLocation"
        >
          {{ neo_btn }}
        </button>
      </form>
    </div>

    <div class="neo__locator-block_image">
      <NeoImage
        v-if="neo_image"
        :src="neo_image"
        :alt="'Dealer Locator image'"
        :class="'neo__locator-block__image'"
      >
      </NeoImage>
    </div>
  </div>
</template>

<script>
import NeoImage from "./NeoImage.vue";

export default {
  name: "NeoLocatorBlock",
  components: {
    NeoImage,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      neo__locatorInput: "",
    };
  },
  computed: {
    neo_title() {
      return this.title;
    },
    neo_description() {
      return this.description;
    },
    neo_image() {
      return this.image;
    },
    neo_btn() {
      return this.search;
    },
  },
  methods: {
    submitLocation() {
      window.location =
        "/consument/vind-een-installateur?place=" + this.neo__locatorInput;
    },
  },
};
</script>
