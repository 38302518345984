<template>
  <div
    class="partial__block partial__custom-tool partial__gas-usage-calculator"
  >
    <div class="partial__block--content">
      <form class="calculator__form" @submit.prevent>
        <h2 class="partial--title h4">
          Hoeveel bomen er nodig zijn om jouw gasverbruik te compenseren ?
        </h2>

        <label class="form-input">
          <p>Mijn gasverbruik in het afgelopen jaar:</p>
          <input v-model="gasUse" class="input" type="number" placeholder="0" />
          <span class="extension">&#13221;</span>
        </label>

        <span class="divider"></span>

        <label class="form-answer">
          <p>De CO<sup>&#x2082;</sup>-uitstoot is:</p>
          <span class="input">{{ kgAmount }}</span>
          <span class="extension"> kg</span>
        </label>
        <label class="form-answer">
          <p>
            Het aantal bomen dat nodig is om deze CO<sup>&#x2082;</sup> uit de
            lucht te halen:
          </p>
          <span class="input">{{ treesNeeded }}</span>
          <button
            type="button"
            class="toggle-modal__btn"
            @click="toggleModal()"
          >
            <span class="btn--text">{{ modal ? "x" : "i" }}</span>
          </button>
        </label>
      </form>

      <div v-if="modal" class="calculator__modal">
        <div class="modal--content">
          <button
            type="button"
            class="toggle-modal__btn"
            @click="toggleModal()"
          >
            <span class="btn--text">{{ modal ? "x" : "i" }}</span>
          </button>
          <p class="wysiwyg">
            Hoeveel CO<sup>&#x2082;</sup> een boom opneemt, hangt van veel
            variabelen af.<br />In onze CO<sup>&#x2082;</sup>-projecten is dit
            gemiddeld 200kg
          </p>
        </div>
      </div>
    </div>

    <div class="partial__block--image">
      <UiImage
        :src="`${siteUrl}assets/neo/green-logo.png`"
        :alt="'green plant image'"
      >
      </UiImage>
    </div>
  </div>
</template>

<script>
import UiImage from "./UiImage.vue";

export default {
  name: "PartialsCustomToolGasUsageCalculator",
  components: {
    UiImage,
  },
  props: {
    siteUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gasUse: 0,
      modal: false,
    };
  },
  computed: {
    realCo2() {
      // 1m3 -> 2kg
      return this.gasUse * 2;
    },
    //
    kgAmount() {
      return Math.ceil(this.realCo2);
    },
    // 200kg -> 1 boom
    treesNeeded() {
      return Math.ceil(this.realCo2 * 0.005);
    },
  },
  methods: {
    toggleModal() {
      this.modal = !this.modal;
    },
  },
};
</script>
