/* IE11 forEach polyfill */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (document.readyState !== "loading") {
  myInitCode();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    myInitCode();
  });
}

function myInitCode() {
  const listElements = document.querySelectorAll(".triptych__item");

  if (listElements && listElements.length > 0) {
    listElements.forEach(function (btn, index) {
      btn.addEventListener("click", function () {
        switchListItemState(index);
      });
    });
  }

  function switchListItemState(event) {
    listElements.forEach(function (btn, index) {
      if (event === index) {
        if (btn.classList.contains("item-is-active")) {
          btn.classList.remove("item-is-active");
        } else {
          btn.classList.add("item-is-active");
        }
      } else if (btn.classList.contains("item-is-active")) {
        btn.classList.remove("item-is-active");
      }
    });
  }
}
