if (document.readyState !== "loading") {
  myInitCode();
} else {
  window.addEventListener("load", function () {
    myInitCode();
  });
}

function myInitCode() {
  const idLinks = document.querySelectorAll("[href^='#']");

  // Helps with page refresh going to the right target block.
  history.scrollRestoration = "manual";

  // On page refresh it scrolls to whatever the Hash is.
  const id = window.location.hash.slice(1);
  if (id.length > 1) {
    scrollToID(id);
  }

  for (let i = 0; i < idLinks.length; i++) {
    idLinks[i].addEventListener("click", function (e) {
      e.preventDefault();

      /* Turns links like 'website.test/branch#id' into just the 'id' */
      const hash = idLinks[i].href.split("#")[1];
      window.location.hash = `#${hash}`;
      scrollToID(hash);
    });
  }

  function scrollToID(id) {
    let target = document.getElementById(id);

    const navigationHeight =
      document.getElementsByClassName("header__outer")[0]?.clientHeight |
      document.getElementsByClassName("header--mobile")[0]?.clientHeight |
      182;

    // Gets TOTAL offset top even if elements are nested in other offset elements.
    let offsetTop = -navigationHeight;
    while (target) {
      offsetTop += target.offsetTop;
      target = target.offsetParent;
    }

    scrollTo(0, offsetTop);
  }
}
