/* IE11 forEach polyfill */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (document.readyState !== "loading") {
  myInitCode();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    myInitCode();
  });
}

function myInitCode() {
  const formGroupSubmit = document.querySelectorAll(".find-dealer__cta");
  let formGroupInput = document.querySelectorAll(".find-dealer__form-group");

  // Press enter in field
  if (formGroupInput && formGroupInput.length > 0) {
    formGroupInput = formGroupInput[0].firstElementChild;

    formGroupInput.addEventListener("keyup", function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        const value = formGroupInput.value;
        window.location.href = `/consument/vind-een-installateur?place=${value}`;
      }
    });
  }
  // Click on submit
  formGroupSubmit.forEach(function (btn) {
    btn.addEventListener("click", function (event) {
      goToDealers(event);
    });
  });

  function goToDealers(item) {
    const parent = item.target.parentElement;
    const value = parent.firstElementChild.value;
    window.location.href = `/consument/vind-een-installateur?place=${value}`;
  }
}
