<template>
  <div v-if="dropdownLinks" class="dropdown-link-navigation__outer">
    <h3 v-if="dropdownTitle" class="dropdown-link-navigation__title">
      {{ dropdownTitle }}
    </h3>
    <select v-model="dropdownIndex" class="dropdown-link-navigation__options">
      <option
        v-for="(i, index) in dropdownLinks"
        :key="`dropdown-value--${index}`"
        :value="index"
        :disabled="index == 0"
      >
        {{ i.text }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "UiDropdownLinkNavigation",
  props: {
    dropdownTitle: {
      type: String,
      default: null,
    },
    dropdownLinks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dropdownIndex: 0,
    };
  },
  watch: {
    dropdownIndex() {
      if (
        this.dropdownIndex > 0 &&
        this.dropdownLinks[this.dropdownIndex] &&
        this.dropdownLinks[this.dropdownIndex].url
      ) {
        if (this.dropdownLinks[this.dropdownIndex].target) {
          window
            .open(this.dropdownLinks[this.dropdownIndex].url, "_blank")
            .focus();
        } else {
          window.location.href = this.dropdownLinks[this.dropdownIndex].url;
        }
      }
    },
  },
};
</script>
