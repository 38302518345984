<template>
  <div class="neo__content-block neo__tabs_outer">
    <div class="neo__tabs-tab-holder">
      <div v-for="(tab, index) in tabs" :key="`tab__${index}`">
        <button
          class="neo__tabs-tab-button"
          :class="[
            { active: currentIndex === index },
            { 'active-left': currentIndex === index - 1 },
            { 'active-right': currentIndex === index + 1 },
          ]"
          @click="currentIndex = index"
        >
          {{ tab.title }}
        </button>
      </div>
      <div>
        <button
          class="neo__tabs-tab-button"
          :class="{
            active: currentIndex === tabs.length,
            'active-left': currentIndex === tabs.length - 1,
          }"
          @click="currentIndex = tabs.length"
        >
          Veelgestelde vragen
        </button>
      </div>
    </div>

    <div class="neo__tab">
      <div v-for="(tab, index) in tabs" :key="`tab__${index}`">
        <transition name="fade">
          <div v-if="currentIndex === index" class="neo__tab-content-holder">
            <div
              v-for="(content, i) in tab.content"
              :key="`tab-content__${i}`"
              class="neo__tab-content"
            >
              <div
                class="neo__tab-content-content"
                :class="{ 'neo__tab-content-content-full': !content.media }"
              >
                <span v-if="!!content.dateString" class="neo__tab-content-date"
                  >Update: {{ content.dateString }}</span
                >
                <h3 class="neo__tab-content-title" v-text="content.title"></h3>
                <div
                  class="neo__tab-content-text neo__wysiwyg wysiwyg"
                  v-html="content.text"
                ></div>
                <div class="neo__tab-content-ctas neo__cta-list">
                  <div
                    v-for="(cta, j) in content.ctas"
                    :key="`cta__${j}`"
                    v-html="cta.html"
                  ></div>
                </div>
              </div>
              <template v-if="!!content.media">
                <div
                  class="neo__tab-content-media"
                  :class="'neo__tab-content-media-' + content.media.type"
                >
                  <div
                    v-if="content.media.type === 'image'"
                    class="neo__tabs-image-holder"
                  >
                    <NeoImage :src="content.media.src" :alt="content.media.alt">
                    </NeoImage>
                  </div>
                  <div v-else-if="content.media.type === 'video'">
                    <video async defer width="320" height="240" autoplay>
                      <source
                        :src="content.media.src"
                        :type="'video/' + content.media.format"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div
                    v-else-if="content.media.type === 'youtube'"
                    v-html="youtubeEscape(content.media.html)"
                  ></div>
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <transition name="fade">
        <slot v-if="currentIndex === tabs.length"></slot>
      </transition>
    </div>
    <div class="neo__tabs_background"></div>
  </div>
</template>

<script>
import NeoImage from "./NeoImage.vue";
export default {
  name: "NeoTabs",
  components: {
    NeoImage,
  },
  props: {
    tabsJson: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentIndex: 0,
      lastIndex: 0,
    };
  },
  computed: {
    tabs() {
      return this.tabsJson ? JSON.parse(this.tabsJson) : null;
    },
  },
  watch: {
    currentIndex(v) {
      setTimeout(() => {
        this.lastIndex = v;
      }, 100);
    },
  },
  methods: {
    youtubeEscape(html) {
      html = html.replace(":src", "src");
      html = html.replace("'\"", '"');
      html = html.replace("\"'", '"');
      return html;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;

  & + & {
    position: absolute;
    top: 0;
    /*left: 0;*/
  }
}

.fade-enter-active {
  transform: translate(-100%);
}

.fade-leave-active + .fade-enter-active {
  transform: translate(100%);
}

.fade-enter-to {
  transform: none !important;
}

.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  /*opacity: 0;*/
  transform: translateX(-100%);
}

.fade-enter-to + .fade-leave-to {
  transform: translate(100%);
}
</style>
