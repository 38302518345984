<template>
  <div class="savings-form">
    <p class="savings-form__title">{{ content.headerFormTitle }}</p>
    <form @submit.prevent="submit()">
      <fieldset>
        <label class="postal-code"
          >Postcode<input
            v-model="postal_code"
            type="text"
            name="postal_code"
            autocomplete="postal-code"
            pattern="[0-9]{4}[A-Za-z]{2}"
            required
            onkeypress="return event.key != 32"
        /></label>
        <label class="street-number"
          >Huisnummer<input
            v-model="street_number"
            type="text"
            inputmode="numeric"
            pattern="[0-9]+"
            autocomplete="address-line2"
            required
        /></label>
        <label class="street-number-suffix"
          >Toevoeging<input v-model="street_number_suffix" type="text"
        /></label>
        <label class="savings-toggle">
          <p class="savings-toggle__text">Ik heb vloerverwarming</p>
          <input v-model="underfloor_heating" type="checkbox" />
          <div class="savings-toggle__check">
            <span class="on">ja</span>
            <span class="off">nee</span>
          </div>
        </label>
      </fieldset>
      <div class="form-buttons">
        <button type="submit" class="cta link icon--next btn">
          {{ content.headerCtaText }}
        </button>
        <a
          v-if="content.headerReadMore"
          class="link link--full"
          :href="content.headerReadMore.url"
          :target="content.headerReadMore.target"
          >{{ content.headerReadMore.text }}</a
        >
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "SavingsForm",
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      postal_code: null,
      street_number: null,
      street_number_suffix: null,
      underfloor_heating: false,
    };
  },
  watch: {
    postal_code(value) {
      this.postal_code = value.replace(/ +/g, "");
    },
  },
  mounted() {
    this.checkIfAddressIsSet();
  },
  methods: {
    checkIfAddressIsSet() {
      const address = JSON.parse(sessionStorage.getItem("nb_address"));
      if (address) {
        this.postal_code = address.postal_code;
        this.street_number = address.street_number;
        this.street_number_suffix = address.street_number_suffix;
        this.underfloor_heating = address.underfloor_heating;
      }
    },
    submit() {
      const address = {
        postal_code: this.postal_code,
        street_number: this.street_number,
        street_number_suffix: this.street_number_suffix,
        underfloor_heating: this.underfloor_heating,
      };
      sessionStorage.setItem("nb_address", JSON.stringify(address));
      window.dispatchEvent(new CustomEvent("sessionstorage-changed"));
    },
  },
};
</script>
