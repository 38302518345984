var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.parsedImages[_vm.currentImage].url)?_c('div',{staticClass:"partials__neo-product-details"},[_c('div',{staticClass:"image__container product-details__image--big"},[_c('img',{staticClass:"image",staticStyle:{"background-position":"50% 50%","object-position":"50% 50%"},attrs:{"alt":_vm.parsedImages[_vm.currentImage].title
          ? _vm.parsedImages[_vm.currentImage].title
          : _vm.parsedImages[_vm.currentImage].filename,"aria-label":_vm.parsedImages[_vm.currentImage].title
          ? _vm.parsedImages[_vm.currentImage].title
          : _vm.parsedImages[_vm.currentImage].filename,"src":_vm.parsedImages[_vm.currentImage].url}})]),_c('ul',{staticClass:"product-details__preview"},[(_vm.parsedImages.length > 4)?_c('li',{staticClass:"preview__item preview__controlls"},[_c('button',{attrs:{"disabled":_vm.currentImage - 1 <= 0},on:{"click":function($event){return _vm.setCurrentImage(_vm.currentImage - 1)}}},[_vm._v(" ʌ ")])]):_vm._e(),_vm._l((_vm.parsedImages),function(image,index){return _c('li',{key:index,staticClass:"preview__item",class:[
        { 'item--active': _vm.currentImage === index },
        {
          'item--hidden':
            (_vm.parsedImages.length > 4 && index <= _vm.displayRange['start']) ||
            (_vm.parsedImages.length > 4 && index >= _vm.displayRange['end']),
        },
      ]},[_c('button',{on:{"click":function($event){return _vm.setCurrentImage(index)}}},[_c('UiImage',{attrs:{"src":image.url,"alt":image.title}})],1)])}),(_vm.parsedImages.length > 4)?_c('li',{staticClass:"preview__item preview__controlls"},[_c('button',{attrs:{"disabled":_vm.currentImage + 1 >= _vm.parsedImages.length},on:{"click":function($event){return _vm.setCurrentImage(_vm.currentImage + 1)}}},[_vm._v(" v ")])]):_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }