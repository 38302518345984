<script>
import { Doughnut } from "vue-chartjs";

export default {
  name: "DoughnutChart",
  extends: Doughnut,
  props: {
    styles: {
      type: Object,
      default: null,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: this.datasets,
        styles: this.styles,
        responsive: true,
      },
      this.options
    );
    const chartLegends = document.getElementById("chart-legends");
    if (chartLegends && this.datasets) {
      chartLegends.innerHTML = this.generateCutomLegend();
    }
  },
  methods: {
    generateCutomLegend() {
      const template = `
            <ul class="chart-legend">
              ${this.labels
                .map((label, index) => {
                  return `
                <li class="chart-label-item" v-if="content">
                  <span class="label-block" style="background-color: ${this.datasets[0].backgroundColor[index]}"></span>${label}
                </li>`;
                })
                .join("")}
            </ul>
          `;
      return template;
    },
  },
};
</script>
