<template>
  <div>
    <article v-if="!error && content.body && content.title">
      <section v-html="content.body"></section>
    </article>
    <article v-else-if="error">
      <h3>Er ging iets mis bij het ophalen van de gegevens..</h3>
    </article>
    <article v-else>
      <h3>Geen inhoud.</h3>
    </article>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NeoPrivacyStatementFetcher",
  props: {
    entrySlug: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      revisionUrl: null,
      revisionID: Number,
      content: {
        title: String,
        body: String,
      },
      error: null,
    };
  },

  async mounted() {
    this.revisionUrl =
      this.entrySlug === "privacy-policy"
        ? process.env.VUE_APP_BOSCH_GDPR_PRIVACY_POLICY_V2
        : process.env.VUE_APP_BOSCH_GDPR_PRIVACY_POLICY;
    if (this.revisionUrl) {
      this.getContent();
    }
  },
  methods: {
    getContent() {
      axios
        .get(this.revisionUrl)
        .then((res) => {
          this.revisionID =
            res.data && res.data.length > 0 && res.data[0].revision
              ? `/revision/${res.data[0].revision}`
              : "";
        })
        .then(() => {
          axios.get(this.revisionUrl + this.revisionID).then((res) => {
            this.content = {
              title: res.data.name,
              body: res.data.text,
            };
          });
        })
        .catch((error) => {
          this.error = error;
          console.log(this.error);
        });
    },
  },
};
</script>
