<template>
  <div class="neo__content-block full neo__featured-product-list-block__outer">
    <div class="neo__featured-product-list-block__innerbackground"></div>
    <carousel
      :navigation-enabled="true"
      navigation-next-label=""
      navigation-prev-label=""
      :navigation-click-target-size="36"
      :pagination-enabled="true"
      :per-page-custom="[
        [0, Math.min(parsedProducts.length, 1)], //shows 1 items from 0 Pixels to 568 Pixels
        [768, Math.min(parsedProducts.length, 2)], //shows 2 items from 568 Pixels to 972 Pixels
        [992, Math.min(parsedProducts.length, 3)], //shows 3 items from 972 Pixels to 1600 Pixels
        [1750, Math.min(parsedProducts.length, 4)], // shows 4 items above 1750 pixels
      ]"
      :scroll-per-page="true"
      class="neo__featured-product-list-block__inner"
    >
      <slide
        v-for="(product, index) in parsedProducts"
        :key="`products__${index}`"
        class="neo__featured-product-category-list-item__outer"
      >
        <div class="neo__featured-product-category-list-item">
          <div class="neo__featured-product-category-list-item-top">
            <div
              class="neo__featured-product-category-list-item-top-image-holder"
            >
              <NeoImage
                v-if="product.imgsrc"
                :src="product.imgsrc"
                :alt="'product categorie'"
              ></NeoImage>
            </div>
            <h3
              class="neo__featured-product-category-list-item-top-title"
              v-text="product.title"
            ></h3>
            <div
              class="neo__featured-product-category-list-item-top-content neo__wysiwyg"
              v-html="product.text"
            ></div>
          </div>
          <div class="neo__featured-product-category-list-item-bottom">
            <div
              v-for="(feat, i) in visableProducts(product.featured)"
              :key="`feat__${i}`"
              class="neo__featured-product-category-list-item-bottom-featured"
            >
              <a :href="feat.url" class="neo__cta link">
                <div
                  class="neo__featured-product-category-list-item-bottom-image-holder"
                >
                  <NeoImage
                    v-if="feat.imageSrc"
                    :src="feat.imageSrc"
                    :alt="'product categorie'"
                  ></NeoImage>
                </div>
                <div
                  class="neo__featured-product-category-list-item-bottom-title-text arrow"
                  v-text="feat.title"
                ></div>
              </a>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import NeoImage from "./NeoImage.vue";

export default {
  name: "NeoFeaturedProductList",
  components: {
    carousel: Carousel,
    slide: Slide,
    NeoImage,
  },
  props: {
    productsString: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    parsedProducts() {
      return JSON.parse(this.productsString);
    },
  },
  methods: {
    visableProducts(data) {
      return data.filter(function (product) {
        return product && "imageSrc" in product;
      });
    },
  },
};
</script>
