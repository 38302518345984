<template>
  <div class="quizTool">
    <!-- HEADER -->
    <div class="quizTool__header">
      <div class="top-border"></div>
      <div class="quizTool__header-content">
        <slot name="logo"></slot>
        <div class="button-controls">
          <a :href="`${siteUrl}/klantenservice/#vragen`" class="questions">
            <span class="questions">Vragen?</span>
            <span class="icon">?</span>
          </a>
          <a :href="closeUrl" class="closeAdviceTool">
            <span class="description"
              ><img src="../assets/img/icons/close.svg"
            /></span>
          </a>
        </div>
      </div>
    </div>

    <!-- PROGRESBAR -->
    <div
      class="quizTool__progressbar"
      :style="`width: calc((${progress} / ${parsedQuestions.length}) * 100% + 20px)`"
    ></div>

    <!-- CONTENT -->
    <div class="quizTool__content">
      <div
        v-for="(item, itemIndex) in parsedQuestions"
        :key="itemIndex"
        class="question__container"
        :class="[
          { show: itemIndex === currentQuestion },
          { hide: itemIndex !== currentQuestion },
        ]"
      >
        <h3 class="question__title">{{ item.question }}</h3>
        <p class="question__description">{{ item.description }}</p>
        <p class="question__progress-text">
          Vraag <span>{{ progress + 1 }}</span> van {{ parsedQuestions.length }}
        </p>

        <div class="answer_container">
          <div
            v-for="(answer, index) in item.answers"
            :key="index"
            class="answer__block"
            :class="[{ active: userChoices[itemIndex] === getLetter(index) }]"
            @click="setUserChoice(itemIndex, getLetter(index))"
          >
            <div class="answer__indicator">
              <span>{{ getLetter(index) }}</span>
            </div>
            <div class="answer__text">{{ answer }}</div>
          </div>
        </div>
      </div>
      <div
        class="quizControls"
        :class="{
          hide:
            currentQuestion === parsedQuestions.length &&
            parsedAnswers[matchingResultIndex],
        }"
      >
        <button
          v-if="currentQuestion > 0"
          class="cta btn icon--back"
          @click="goBack()"
        >
          <span>Vorige vraag</span>
        </button>
      </div>
    </div>

    <!-- RESULTS -->
    <div
      v-if="
        currentQuestion === parsedQuestions.length &&
        parsedAnswers[matchingResultIndex]
      "
      class="quizTool__results"
    >
      <div class="quizTool__calculatedResult">
        <div class="quizTool__result-content">
          <div class="quizTool__back-container">
            <button class="cta icon--backblack" @click="goBack()">
              <span>Vorige vraag</span>
            </button>
          </div>
          <h3 v-if="parsedAnswers[matchingResultIndex]" class="title">
            {{ parsedAnswers[matchingResultIndex].title }}
          </h3>
          <h5 class="heading">Ons advies</h5>
          <div class="description">
            <div class="regular-text">
              <p v-html="parsedAnswers[matchingResultIndex].description"></p>
            </div>
            <div class="cta-container">
              <a
                class="cta btn icon--next"
                :href="parsedAnswers[matchingResultIndex].cta"
                >Bekijk meer</a
              >
              <a :href="parsedAnswers[matchingResultIndex].quotationLink"
                >Of vraag een offerte aan</a
              >
            </div>
          </div>
        </div>
        <div class="product-container">
          <img
            :src="parsedAnswers[matchingResultIndex].imageUrl"
            :alt="parsedAnswers[matchingResultIndex].imageAlt"
            class="prduct-image"
          />
        </div>
      </div>
      <section class="quizTool__alternative">
        <div class="title-container">
          <h3 class="subtitle">Onze andere opties</h3>
        </div>
        <div class="alternative-container">
          <div
            v-for="(item, index) in parsedAnswers[matchingResultIndex]
              .alternative"
            :key="index"
            class="alternative-card"
          >
            <div class="heading">
              <img :src="item.imageUrl" :alt="item.imageAlt" class="image" />
            </div>
            <div class="body">
              <h4 class="title">{{ item.title }}</h4>
              <p class="description" v-html="item.description"></p>
              <a class="cta btn icon--next button" :href="item.link"
                >Bekijken</a
              >
            </div>
          </div>
        </div>
      </section>

      <section class="block quizTool__footer">
        <div class="quizTool__footer-block">
          <div class="quizTool__footer-block-content">
            <h3
              v-if="parsedOfferData.ctaBlockTitle"
              class="quizTool__footer-block-title"
            >
              {{ parsedOfferData.ctaBlockTitle }}
            </h3>

            <div
              v-if="parsedOfferData.ctaBlockBody"
              class="quizTool__footer-block-body wysiwyg"
            >
              <p>{{ parsedOfferData.ctaBlockBody }}</p>
            </div>

            <a
              v-if="
                offerteLink &&
                parsedOfferData.ctaButtonLink &&
                parsedOfferData.ctaButtonLink.text
              "
              :href="parsedAnswers[matchingResultIndex].quotationLink"
              class="cta btn icon--next"
              :aria-label="parsedOfferData.ctaButtonLink.text"
            >
              <span>{{ parsedOfferData.ctaButtonLink.text }}</span>
            </a>
            <a
              v-else-if="
                parsedOfferData.ctaButtonLink &&
                parsedOfferData.ctaButtonLink.text
              "
              :href="offerteLink"
              class="cta btn icon--next"
              :aria-label="parsedOfferData.ctaButtonLink.text"
            >
              <span>{{ parsedOfferData.ctaButtonLink.text }}</span>
            </a>
          </div>

          <div class="quizTool__footer-block-image">
            <img
              class="image lozad"
              alt="advise support image"
              aria-label="advise support image"
              :src="parsedOfferData.ctaBlockImage[0].url"
              data-loaded="true"
            />
          </div>
        </div>

        <PartialsLocatorBlock
          classes="classes"
          :title="parsedLocatorData.locatorBlockTitle"
          :image="parsedLocatorData.locatorBlockImage[0].url"
        >
        </PartialsLocatorBlock>
      </section>
    </div>
  </div>
</template>

<script>
import PartialsLocatorBlock from "./PartialsLocatorBlock.vue";

export default {
  name: "NeoQuizTool",
  components: { PartialsLocatorBlock },
  props: {
    quizTitle: {
      type: String,
      required: true,
    },
    questions: {
      type: String,
      required: true,
    },
    answers: {
      type: String,
      required: true,
    },
    answersKeys: {
      type: String,
      required: true,
    },
    offerData: {
      type: String,
      required: true,
    },
    locatorData: {
      type: String,
      required: true,
    },
    siteUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      progress: 0,
      userChoices: [],
      currentQuestion: 0,
      matchingResultIndex: 0,
      offerteLink: null,
    };
  },
  computed: {
    closeUrl() {
      return window.location.pathname;
    },
    parsedQuestions() {
      return JSON.parse(this.questions);
    },
    parsedAnswers() {
      return JSON.parse(this.answers);
    },
    parsedAnswersKeys() {
      return JSON.parse(this.answersKeys);
    },
    parsedOfferData() {
      return JSON.parse(this.offerData);
    },
    parsedLocatorData() {
      return JSON.parse(this.locatorData);
    },
  },
  methods: {
    goBack() {
      this.progress -= 1;
      this.currentQuestion -= 1;
    },
    setUserChoice(index, value) {
      this.userChoices[index] = value;
      this.currentQuestion = index + 1;
      this.progress = index + 1;
      this.getMatchingresult();
    },
    getLetter(value) {
      const dataContainer = "abcdefghijklmnopqrstuvwxyz";
      return dataContainer.charAt(value);
    },
    getMatchingresult() {
      this.matchingResultIndex = 0;
      if (this.userChoices.length === this.parsedQuestions.length) {
        const self = this;
        this.parsedAnswersKeys.forEach(function (element) {
          if (self.userChoices.toString() === element.answerKey.toString()) {
            self.matchingResultIndex = element.answerResponse;
          }
        });
      }
      this.getOfferteLink();
    },
    getOfferteLink() {
      if (this.parsedAnswers[this.matchingResultIndex]) {
        if (
          this.parsedAnswers[this.matchingResultIndex].title.includes(
            "nieuwe cv-ketel"
          )
        ) {
          this.offerteLink =
            "https://cv-ketel.nefit-bosch.nl/cv-ketel/uw_gegevens";
        } else if (
          this.parsedAnswers[this.matchingResultIndex].title.includes(
            "hybride warmtepomp"
          )
        ) {
          this.offerteLink = "https://hybride.nefit-bosch.nl/hybride/";
        } else if (
          this.parsedAnswers[this.matchingResultIndex].title.includes(
            "elektrische warmtepomp"
          )
        ) {
          this.offerteLink =
            "https://warmtepomp.nefit-bosch.nl/warmtepomp/uw_gegevens?beweegreden=helemaal_zonder_gas";
        }
      }
    },
  },
};
</script>

<style lang="scss">
.quizTool {
  .col-6 {
    position: relative;
    border: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    height: 100%;
    > div {
      margin: 0;
      padding: 18px;
      box-sizing: border-box;
    }
    .neo__locator-block__title,
    .neo__advice__cta-block__title {
      margin: 0;
      .neo__locator-block_content {
        padding: 0;
      }
    }
  }
}
</style>
