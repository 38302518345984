<template>
  <div
    v-if="parsedImages[currentImage].url"
    class="partials__neo-product-details"
  >
    <div class="image__container product-details__image--big">
      <img
        class="image"
        :alt="
          parsedImages[currentImage].title
            ? parsedImages[currentImage].title
            : parsedImages[currentImage].filename
        "
        :aria-label="
          parsedImages[currentImage].title
            ? parsedImages[currentImage].title
            : parsedImages[currentImage].filename
        "
        :src="parsedImages[currentImage].url"
        style="background-position: 50% 50%; object-position: 50% 50%"
      />
    </div>

    <ul class="product-details__preview">
      <li
        v-if="parsedImages.length > 4"
        class="preview__item preview__controlls"
      >
        <button
          :disabled="currentImage - 1 <= 0"
          @click="setCurrentImage(currentImage - 1)"
        >
          ʌ
        </button>
      </li>

      <li
        v-for="(image, index) in parsedImages"
        :key="index"
        class="preview__item"
        :class="[
          { 'item--active': currentImage === index },
          {
            'item--hidden':
              (parsedImages.length > 4 && index <= displayRange['start']) ||
              (parsedImages.length > 4 && index >= displayRange['end']),
          },
        ]"
      >
        <button @click="setCurrentImage(index)">
          <UiImage :src="image.url" :alt="image.title"> </UiImage>
        </button>
      </li>

      <li
        v-if="parsedImages.length > 4"
        class="preview__item preview__controlls"
      >
        <button
          :disabled="currentImage + 1 >= parsedImages.length"
          @click="setCurrentImage(currentImage + 1)"
        >
          v
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import UiImage from "./UiImage.vue";

export default {
  name: "PartialsNeoProductDetails",
  components: {
    UiImage,
  },
  props: {
    images: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentImage: 0,
    };
  },
  computed: {
    displayRange() {
      let range = {};
      if (this.parsedImages.length > 4) {
        if (this.currentImage === 0) {
          range = {
            check: "if",
            start: 0,
            end: 4,
          };
        } else if (this.currentImage >= this.parsedImages.length - 3) {
          range = {
            check: "else if",
            start: this.parsedImages.length - 4,
            end: this.parsedImages.length,
          };
        } else {
          range = {
            check: "else",
            start: this.currentImage - 1,
            end: this.currentImage + 3,
          };
        }
      } else {
        range = { start: 0, end: 4 };
      }
      return range;
    },
    currentItem() {
      return this.parsedImages[this.currentImage];
    },
    parsedImages() {
      return JSON.parse(this.images);
    },
  },
  methods: {
    setCurrentImage(e) {
      this.currentImage = this.currentImage !== e ? e : this.currentImage;
    },
  },
};
</script>
