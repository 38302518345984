const body = document.querySelector("body");
const header_container = document.querySelector(".header--mobile");
const content_slides = document.querySelectorAll(".content__slide");

const toggle__menu = document.querySelectorAll(".header__toggle");
const back__buttons = document.querySelectorAll(".item__back");
const mobile__buttons = document.querySelectorAll(
  ".header__menu--mobile .item__next"
);

// Desktop
const desktop__container = document.querySelector(".header__menu--desktop");
const content_tabs = document.querySelectorAll(".content__tab");
const desktop__buttons = document.querySelectorAll(
  ".header__menu--desktop .item__next"
);
const desktop__hover = document.querySelectorAll(".item__hover");
const desktop_exit = document.querySelectorAll(".menu__exit");
const desktop_close = document.querySelectorAll(".menu__close");

const active_items_mobile = {
  menu: "closed",
  1: null,
  2: null,
  3: null,
  4: null,
};

const active_items_desktop = {
  1: null,
  2: null,
};

function addListeners() {
  // Mobile
  toggle__menu.forEach(function (btn) {
    btn.addEventListener("click", function () {
      update_mobile_states(
        btn,
        "menu",
        active_items_mobile.menu === "closed" ? "open" : "closed"
      );
    });
  });
  mobile__buttons.forEach(function (btn) {
    btn.addEventListener("click", function () {
      update_mobile_states(btn, btn.dataset.level, btn.dataset.title);
    });
  });
  back__buttons.forEach(function (btn) {
    btn.addEventListener("click", function () {
      update_mobile_states(btn, btn.dataset.level, btn.dataset.title);
    });
  });

  // Desktop
  desktop__buttons.forEach(function (btn) {
    btn.addEventListener("click", function () {
      update_desktop_states(btn, btn.dataset.level, btn.dataset.title);
    });
  });

  desktop__hover.forEach(function (btn) {
    btn.addEventListener("mouseover", function () {
      update_desktop_states(btn, btn.dataset.level, btn.dataset.title);
    });
  });
  desktop_exit.forEach(function (btn) {
    btn.addEventListener("mouseover", function () {
      update_desktop_states(btn, "1", null);
    });
  });
  desktop_close.forEach(function (btn) {
    btn.addEventListener("click", function () {
      update_desktop_states(btn, "1", null);
    });
  });
}

function update_desktop_states(btn, level, value = null) {
  // Add value to header
  active_items_desktop[level] = value;
  desktop__container.dataset[level] = active_items_desktop[level];

  // Check if menu is open
  if (
    desktop__container.dataset[level] !== null &&
    desktop__container.dataset[level] !== "null"
  ) {
    header_container.classList.add("menu--open");
  } else {
    header_container.classList.remove("menu--open");
  }

  // Style buttons
  desktop__buttons.forEach(function (btn) {
    btn.classList.remove("active");
    if (btn.dataset.title === value) {
      btn.classList.add("active");
    }
  });
  if (level === "1") {
    desktop__hover.forEach(function (btn) {
      btn.classList.remove("active");
      if (btn.dataset.title === value) {
        btn.classList.add("active");
      }
    });
  }

  // Show/Hide tabs
  content_tabs.forEach(function (tab) {
    if (Number(tab.dataset.level) >= Number(level)) {
      tab.classList.remove("active");
    }
    if (
      Number(tab.dataset.level) === Number(level) &&
      tab.dataset.title === value
    ) {
      tab.classList.add("active");
    }
  });
}

function update_mobile_states(btn, level, value = null) {
  // Add value to header
  active_items_mobile[level] =
    active_items_mobile[level] !== value ? value : null;

  // Check if menu is open
  if (level === "menu") {
    if (active_items_mobile.menu === "closed") {
      header_container.dataset[level] = active_items_mobile[level];
      for (let i = 1; i <= 4; i++) {
        header_container.dataset[`${i}`] = null;
      }
      body.classList.remove("menu--open");
    } else {
      body.classList.add("menu--open");
    }
  }

  header_container.dataset[level] = active_items_mobile[level];
  level = level === "menu" ? "0" : level;

  // Show/Hide slides
  content_slides.forEach(function (slide) {
    if (Number(slide.dataset.level) >= Number(level)) {
      slide.classList.remove("active");
    }
    if (
      Number(slide.dataset.level) === Number(level) &&
      slide.dataset.title === value
    ) {
      slide.classList.add("active");
    }
  });
}

function init() {
  addListeners();
}

if (document.readyState !== "loading") {
  init();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    init();
  });
}
