<template>
  <div :id="id">
    <div
      v-if="isValid"
      data-device-detection=""
      :data-device="properties['data-device']"
    >
      <a :href="properties.href" :rel="properties.rel" :class="anchorClass"
        ><slot>Klik hier voor AR weergave</slot></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductARView",
  props: {
    androidAssetUrl: {
      type: [String, null],
      default: null,
      required: false,
    },
    iosAssetUrl: {
      type: [String, null],
      default: null,
      required: false,
    },
    id: {
      type: String,
      default: "",
      required: false,
    },
    anchorClass: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    isAndroid() {
      return navigator.userAgent.includes("Android");
    },
    isIOS() {
      const platform =
        navigator?.userAgentData?.platform || navigator?.platform || "unknown";

      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document) // iPad on iOS 13 detection
      );
    },
    fullAndroidAssetUrl() {
      if (this.androidAssetUrl) {
        return `intent://arvr.google.com/scene-viewer/1.0?file=${this.androidAssetUrl}&mode=ar_preferred#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
      }

      return null;
    },
    isValid() {
      return (
        (this.isAndroid && this.fullAndroidAssetUrl) ||
        (this.isIOS && this.iosAssetUrl)
      );
    },
    properties() {
      if (this.isAndroid) {
        return {
          "data-device": "android",
          href: this.fullAndroidAssetUrl,
          ref: "",
        };
      }

      if (this.isIOS) {
        return {
          "data-device": "ios",
          rel: "ar",
          href: this.iosAssetUrl,
        };
      }

      return {};
    },
  },
};
</script>
