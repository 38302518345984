<template>
  <div v-if="videoData && videoData.length" class="partial__container">
    <div v-if="video" class="partial__content">
      <video
        ref="videoElement"
        class="video"
        :src="video"
        autoplay
        muted
        playsinline
        @ended="videoEnded"
      ></video>
      <div
        :style="'background-image: url(\'' + placeholder + '\');'"
        class="video-placeholder"
      ></div>
    </div>

    <div
      v-for="(block, index) in videoData"
      :key="index"
      :class="{ active: index === currentIndex }"
      class="video__list__item"
      @mouseover="updateSlide(index, block)"
    >
      <div class="item--index">{{ index + 1 }}</div>
      <div class="item--info">
        <div v-if="block.blockTitle" class="item--title">
          {{ block.blockTitle }}
        </div>
        <p v-if="block.blockBody" class="item--description">
          {{ block.blockBody }}
        </p>
      </div>

      <div class="item--progress">
        <div
          v-if="index === currentIndex"
          :id="`scrubber_${index}`"
          class="scrubber"
          :style="{ width: `${videoProgress}%` }"
        ></div>
        <div
          v-if="index < currentIndex"
          :id="`scrubber_${index}`"
          class="scrubber"
          :style="{ width: `100%` }"
        ></div>
      </div>
    </div>

    <div class="patial__controls">
      <button class="prev" @click="prevSlide">
        <span class="arrow-left"></span>
      </button>
      <button class="next" @click="nextSlide">
        <span class="arrow-right"></span>
      </button>
    </div>
  </div>

  <div v-else class="partial__content--fallback">
    <strong v-if="videoData && !videoData.length"
      >Er zijn geen video's gevonden voor dit product</strong
    >
  </div>
</template>

<script>
export default {
  name: "PartialsNeoVideoBlock",
  props: {
    videos: { type: String, required: true },
  },
  data() {
    return {
      currentIndex: 0,
      videoProgress: 0,
      video: "",
      placeholder: "",
      placeholderTime: 10,
      progress: 0,
      videoData: null,
    };
  },
  computed: {
    currentVideo() {
      return this.videoData[this.currentIndex];
    },
  },
  watch: {
    videoData: {
      handler(d) {
        if (d.length) {
          this.setInitialData().then(() => {
            const vel = this.$refs.videoElement;
            vel.addEventListener(
              "timeupdate",
              () => {
                const ratio = vel.currentTime / vel.duration;
                const fraction = ratio - Math.floor(ratio);
                const percentage = Math.round(100 * fraction);
                this.videoProgress = Number.isInteger(percentage)
                  ? percentage
                  : 0;
              },
              false
            );
          });
        }
      },
    },
  },
  mounted() {
    this.progressVideoData();
  },
  methods: {
    progressVideoData() {
      this.videoData = JSON.parse(this.videos);
    },
    updateSlide(index, slide) {
      const video = slide.blockVideo;
      const image = slide.blockVideoPlaceholder;

      if (index !== this.currentIndex) {
        this.clearTimer();
        this.currentIndex = index;
        if (!video.length) {
          return;
        }
        if (video.length > 0) {
          this.video = video[0].url;
          this.placeholder = "";
        } else {
          this.video = "";
          this.placeholder = image[0].url;
          this.startTimer();
        }
      }
    },
    videoEnded() {
      this.playNext();
    },
    playNext() {
      if (this.currentIndex < this.videoData.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
      this.updateVideo();
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.videoData.length - 1;
      }
      this.updateVideo();
    },
    updateVideo() {
      this.clearTimer();
      if (
        this.currentVideo.blockVideo.length > 0 &&
        this.currentVideo.blockVideo.length // check if video data has been filled in the CMS
      ) {
        this.placeholder = "";
        this.video = this.currentVideo.blockVideo[0].url;
      } else if (this.videoData[0].blockVideoPlaceholder.length) {
        // defaults to the first, but still needs the data from the CMS filled
        this.video = "";
        this.placeholder = this.videoData[0].blockVideoPlaceholder[0].url;
        this.startTimer();
      }
    },
    nextSlide() {
      this.playNext();
    },
    async setInitialData() {
      this.clearTimer();
      const video = this.videoData[0].blockVideo;
      if (!video.length) {
        return;
      }
      if (video && video[0] && video[0].url) {
        this.video = video[0].url;
      } else {
        this.placeholder = this.videoData[0].blockVideoPlaceholder[0].url;
        this.startTimer();
      }
      return Promise.resolve();
    },
    startTimer() {
      window.timer = setTimeout(() => {
        this.progress += 10 / this.placeholderTime;
        if (this.progress < 100) {
          this.startTimer();
        } else {
          this.nextSlide();
          this.clearTimer();
        }
      }, 100);
    },
    clearTimer() {
      this.progress = 0;
      clearTimeout(window.timer);
    },
  },
};
</script>
