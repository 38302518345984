<template>
  <div class="block__tile-wrapper">
    <NeoCompareBlockTile
      v-for="(item, index) in data"
      :key="`neo-compare-block-${index}`"
      :item="item"
      :height="maxHeight"
      @computed-height="equalHeight"
    />
  </div>
</template>
<script>
import NeoCompareBlockTile from "./NeoCompareBlockTile.vue";
export default {
  name: "NeoCompareBlockWrapper",
  components: {
    NeoCompareBlockTile,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      heightArray: [],
    };
  },
  computed: {
    maxHeight() {
      if (this.heightArray.length > 0) {
        return Math.max(...this.heightArray);
      }
      return 0;
    },
  },
  methods: {
    equalHeight(val) {
      this.heightArray.push(val);
    },
  },
};
</script>
