<template>
  <div class="savings-info">
    <div class="savings-info__content">
      <h3 class="title">{{ title }}</h3>
      <div class="redactor" v-html="content"></div>
    </div>

    <picture class="savings-info__image">
      <source
        :type="`image/${image.extension}`"
        :srcset="image.srcset"
        sizes="50vw"
      />
      <img :src="image.image" />
    </picture>
  </div>
</template>

<script>
export default {
  name: "SavingsInfo",
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    image: {
      type: Object,
      default: null,
    },
    cta: {
      type: Object,
      default: null,
    },
    footnote: {
      type: String,
      default: null,
    },
  },
};
</script>
