<template>
  <div
    class="faq-block__outer neo__content-block"
    :class="[
      faqData[0] && faqData[0].categoryTitle ? 'with-categories' : 'full-width',
    ]"
  >
    <div class="faq-block__inner">
      <div
        v-if="faqData[0] && faqData[0].categoryTitle"
        class="faq-block__aside"
      >
        <div class="faq-block__aside__buttons">
          <div v-for="(category, index) in faqData" :key="`cat--${index}`">
            <button
              v-if="category.categoryTitle !== 'EasyControl'"
              :key="`faq-category-${index}`"
              :class="{ active: index === activeIndex }"
              class="neo__cta link"
              @click="updateFaq(index)"
            >
              {{ category.categoryTitle }}
            </button>
            <a
              v-else
              :href="goToEasyControlSite"
              class="neo__cta link"
              target="_Blank"
              >{{ category.categoryTitle }}</a
            >
          </div>
        </div>
      </div>

      <div class="faq-block__main">
        <h5
          v-if="faqData[activeIndex].categoryTitle"
          class="faq-block__main__title"
        >
          {{ faqData[activeIndex].categoryTitle }}
        </h5>
        <div
          v-for="(item, questionIndex) in faqData[activeIndex].questions"
          :key="questionIndex"
          :class="{ open: questionIndex === activeQuestionIndex }"
          class="faq-block__main__question__outer"
        >
          <div
            v-if="item.answer && item.question"
            class="faq-block__main__question"
            @click="toggleActiveQuestion(questionIndex)"
          >
            {{ item.question }}
          </div>
          <div
            v-if="item.answer && item.question"
            class="faq-block__main__answer neo__wysiwyg"
            v-html="item.answer"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartialsFaqBlock",
  props: {
    content: {
      type: [String],
      default: "",
    },
    noCategories: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: 0,
      activeQuestionIndex: 0,
    };
  },
  computed: {
    faqData() {
      return JSON.parse(this.content);
    },
    goToEasyControlSite() {
      return "https://www.bosch-easycontrol.com/nl/nl/easycontrol/veelgestelde-vragen/";
    },
  },
  methods: {
    updateFaq(index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        this.activeQuestionIndex = 0;
      }

      const answersBlockPosition = document
        .querySelector(".question-container")
        .getBoundingClientRect();
      window.scrollTo({
        top: answersBlockPosition.top + window.scrollY - 120,
        left: 0,
        behavior: "smooth",
      });
    },
    toggleActiveQuestion(index) {
      if (index === this.activeQuestionIndex) {
        this.activeQuestionIndex = null;
      } else {
        this.activeQuestionIndex = index;
      }
    },
  },
};
</script>
