<template>
  <section class="block block--contactperson-block bg--waves">
    <div class="container">
      <div class="block--outer">
        <h2 class="block--title">
          <slot />
        </h2>

        <div v-if="persons" class="block--inner">
          <div
            v-for="(item, key) in persons"
            :key="`${item.name}-${key}`"
            class="block--block u-flex-column"
          >
            <a
              v-if="!isEmpty(item.category)"
              class="u-mb-16"
              :href="`#${item.category.value}`"
            >
              {{ item.category.label }}
            </a>

            <div class="block--content">
              <div class="image__container">
                <ImageComponent
                  v-if="item.image"
                  :image="item.image.url"
                  :title="item.image.alt"
                  :srcset-webp="item.image.srcsetWebp"
                  :focal-point="item.image.focalPoint"
                  sizes="(max-width: 768px) 100w, (max-width: 124px) 50w, 170px"
                  classes-image="u-object-fit"
                />
              </div>

              <div class="block--info">
                <div v-if="item.name" class="block--name">
                  {{ getProLabel(item.name) }}
                </div>
                <div v-if="item.function" class="block--function">
                  {{ getProLabel(item.function) }}
                </div>

                <div v-if="item.phone || item.email" class="block--contact">
                  <div v-if="item.phone" class="block--phone">
                    <!-- {{ templateMacro.baseIcon("phone") }} -->
                    <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
                  </div>

                  <div v-if="item.email" class="block--email">
                    <!-- {{ templateMacro.baseIcon("email") }} -->
                    <a :href="`mailto:${item.email}`"> {{ item.email }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ImageComponent from "../elements/ImageComponent.vue";
import { isEmpty } from "lodash";

export default {
  name: "ContactPersons",
  components: {
    ImageComponent,
  },
  props: {
    persons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getProLabel(value) {
      const pattern = "/$pro/i";

      return value.replace(pattern, '<span class="pro-label">Pro</span>');
    },
    isEmpty,
  },
};
</script>
