import { render, staticRenderFns } from "./SavingsFootnote.vue?vue&type=template&id=68881b60"
import script from "./SavingsFootnote.vue?vue&type=script&lang=js"
export * from "./SavingsFootnote.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports