<template>
  <div class="neo__content-block neo__product-nav-block__outer">
    <a :href="back" class="neo__cta link back">Terug</a>
    <carousel
      :navigation-enabled="true"
      navigation-next-label=""
      navigation-prev-label=""
      :navigation-click-target-size="36"
      :pagination-enabled="true"
      :per-page-custom="[
        // shows `auto` items below 568 pixels
        [524, Math.min(parsedProducts.length, 3)], //shows 3 items from 524 pixels
        [768, Math.min(parsedProducts.length, 4)], //shows 4 items from 768 pixels
        [992, Math.min(parsedProducts.length, 5)], // shows 5 items above 992 pixels
        [1024, Math.min(parsedProducts.length, 5)], // shows 5 items above 1024 pixels
        [1400, Math.min(parsedProducts.length, 5)], // shows 6 items above 1400 pixels
        [1600, Math.min(parsedProducts.length, 6)], // shows 6 items above 1600 pixels
      ]"
      :scroll-per-page="true"
      class="neo__product-nav-block__inner"
    >
      <slide
        v-for="(product, index) in parsedProducts"
        :key="`product-nav__${index}`"
        class="neo__product-nav-product"
      >
        <a :href="product.url" :class="{ active: product.isActive }">
          <div class="neo__product-nav-block-image-holder">
            <NeoImage
              :src="product.imageSrc"
              :alt="'Product navigatie item'"
            ></NeoImage>
          </div>
          <p class="neo__cta link">{{ product.title }}</p>
        </a>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import NeoImage from "./NeoImage.vue";

export default {
  name: "NeoCarouselProductNav",
  components: {
    carousel: Carousel,
    slide: Slide,
    NeoImage,
  },
  props: {
    back: {
      type: String,
      default: "",
    },
    productsString: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    parsedProducts() {
      return JSON.parse(this.productsString);
    },
  },
};
</script>
