<template>
  <div class="neo__search-page-block__outer">
    <!-- // Search Page Input field -->
    <div class="neo__content-block">
      <div class="neo__content-block__inner">
        <div class="neo__search-page-block__search-bar">
          <h2 class="neo__search-page-block__search-title">Zoeken</h2>
          <div class="neo__search-page-block__search-form__outer">
            <input
              v-model="term"
              class="neo__search-page-block__search-form form-input"
              placeholder="ik ben op zoek naar..."
              type="text"
              @keypress.enter="searchResults()"
            />
            <button class="neo__cta btn search" @click="searchResults()">
              Zoeken
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- // Search page Result field -->
    <div class="neo__content-block full neo__search-page-block__results">
      <div class="neo__content-block__inner">
        <!-- Loading Search results -->
        <div v-if="loading" class="search-page-block__loading">
          <spinner></spinner>
        </div>

        <!-- Render search Results -->
        <div
          v-if="!loading && resultData"
          class="search-page-block__search-result-list"
        >
          <h4
            v-if="searchTerm && resultData.length > 0"
            class="search-page-block__search-result-list__title"
          >
            {{ resultData.length }}
            {{ resultData.length === 1 ? "resultaat" : "resultaten" }}
          </h4>

          <!-- Search result list -->
          <ul
            v-if="searchTerm && resultData.length > 0"
            class="search-page-block__search-result-list__results"
          >
            <li v-for="(item, index) in resultData" :key="index">
              <a
                v-if="'url' in item && item.url"
                :href="item.url"
                class="search-page-block__search-result-list__result__link"
              >
                <h5
                  v-if="item.title || item.neoCustomH1"
                  class="search-page-block__search-result-list__result__title"
                >
                  {{ item.neoCustomH1 ? item.neoCustomH1 : item.title }}
                </h5>
                <div
                  v-if="item.description"
                  class="search-page-block__search-result-list__result__description"
                  v-html="item.description"
                ></div>
              </a>
            </li>
          </ul>

          <div
            v-if="searchCount > 0 && resultData.length === 0"
            class="search-results"
          >
            <h4 class="search-page-block__search-result-list__fallback">
              Geen resultaten gevonden voor: "{{ searchTerm }}"
            </h4>
          </div>
        </div>

        <!-- Render intro/fallback Results -->
        <div
          v-if="!loading && resultData.length === 0"
          class="search-page-block__search-result-fallback"
        >
          <slot v-if="resultData.length === 0" name="search-intro"></slot>

          <slot v-else name="page-intro"></slot>

          <slot name="link-list"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import spinner from "./Spinner.vue";

export default {
  name: "NeoSearchPageBlock",
  components: {
    spinner,
  },
  data() {
    return {
      entries: [],
      term: "",
      searchTerm: "",
      searching: false,
      loading: false,
      resultData: [],
      linkArrays: {},
      searchCount: 0,
    };
  },
  methods: {
    async searchResults() {
      this.searchTerm = this.term;
      this.searchCount += 1;
      this.loading = true;
      // Send custom event on submit
      this.$gtm.trackEvent({
        event: "search",
        searchTerm: this.searchTerm,
      });

      try {
        const resp = await axios.get(`nefit-api/search/${this.searchTerm}`);
        this.resultData = resp.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
