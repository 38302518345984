<template>
  <div ref="savingsResult" class="savings-result">
    <div v-if="results && results.savings.has_savings" class="container">
      <SavingsAddressBar :address="results.address" />
      <SavingsSavings
        :title="content.savingsTitle"
        :savings="results.savings"
      />
      <Savingsproduct :title="content.adviceTitle" :product="results.product" />
      <SavingsInfo
        :title="content.infoTitle"
        :content="content.infoContent"
        :image="content.infoImage"
      />
      <SavingsInstaller :cta="content.infoCta" />
      <SavingsFootnote :footnote="content.infoFootnote" />
    </div>
    <SavingsException
      v-else-if="errors"
      :title="content.errorTitle"
      :text="content.errorText"
      :cta="content.errorCta"
    />
    <SavingsException
      v-else-if="results && !results.savings.has_savings"
      :title="content.notProfitableTitle"
      :text="content.notProfitableText"
      :cta="content.notProfitableCta"
    />
    <SavingsLoading v-else-if="address" />
  </div>
</template>

<script>
import SavingsAddressBar from "./SavingsAddressBar.vue";
import Savingsproduct from "./SavingsProduct.vue";
import SavingsInfo from "./SavingsInfo.vue";
import SavingsSavings from "./SavingsSavings.vue";
import SavingsInstaller from "./SavingsInstaller.vue";
import SavingsFootnote from "./SavingsFootnote.vue";
import SavingsException from "./SavingsException.vue";
import SavingsLoading from "./SavingsLoading.vue";

export default {
  name: "SavingsResult",
  components: {
    SavingsAddressBar,
    SavingsSavings,
    Savingsproduct,
    SavingsInfo,
    SavingsInstaller,
    SavingsFootnote,
    SavingsException,
    SavingsLoading,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      address: null,
      errors: null,
      results: null,
    };
  },
  mounted() {
    if (sessionStorage.nb_address) {
      this.scrollToResults();
      this.address = JSON.parse(sessionStorage.nb_address);
      this.getData();
    }
    window.addEventListener(
      "sessionstorage-changed",
      () => {
        this.scrollToResults();
        this.address = JSON.parse(sessionStorage.nb_address);
        this.getData();
      },
      false
    );
  },
  methods: {
    getData() {
      if (!this.address) return;

      // reset
      this.errors = null;
      this.results = null;

      fetch("/nefit-api/savings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.address),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw await response.json();
          }
          return response.json();
        })
        .then((data) => {
          this.results = data.data;
        })
        .catch((error) => {
          this.errors = error.message;
        });
    },
    scrollToResults() {
      // Subtract navigation and breadcrumbs height from the amount to scroll
      // so the results don't end up under the navigation bar
      const resultsDistanceFromTop =
        this.$refs.savingsResult.getBoundingClientRect().top;

      const navigationHeight =
        document.getElementsByClassName("header__outer")[0]?.clientHeight |
        document.getElementsByClassName("header--mobile")[0]?.clientHeight |
        182;

      const breadcrumbsHeight = document.getElementsByClassName(
        "neo__breadcrumbs__outer"
      )[0].clientHeight;

      const offset = navigationHeight + breadcrumbsHeight;
      const scrollAmount = resultsDistanceFromTop - offset;
      window.scrollTo({ top: scrollAmount, behavior: "smooth" });
    },
  },
};
</script>
