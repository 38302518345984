<template>
  <div v-if="parsedProducts && parsedProducts.length" class="container">
    <div class="neo__content-block neo__related-products-block__outer">
      <h3 v-if="title.length" class="h4 neo__related-products-block__title">
        {{ title }}
      </h3>
      <carousel
        :navigation-enabled="true"
        navigation-next-label="<div class='neo-epsilon neo-epsilon-blue'></div>"
        navigation-prev-label="<div class='neo-epsilon neo-epsilon-blue neo-epsilon-left'></div>"
        :navigation-click-target-size="36"
        :pagination-enabled="false"
        :per-page-custom="[
          // shows `auto` items below 568 pixels
          [524, Math.min(parsedProducts.length, 3)], //shows 3 items from 524 pixels
          [768, Math.min(parsedProducts.length, 4)], //shows 4 items from 768 pixels
          [992, Math.min(parsedProducts.length, 5)], // shows 5 items above 992 pixels
          [1024, Math.min(parsedProducts.length, 5)], // shows 5 items above 1024 pixels
          [1400, Math.min(parsedProducts.length, 6)], // shows 6 items above 1400 pixels
          [1600, Math.min(parsedProducts.length, 6)], // shows 6 items above 1600 pixels
        ]"
        :scroll-per-page="true"
        class="neo__related-products-block__inner"
      >
        <slide
          v-for="(product, index) in parsedProducts"
          :key="`product__${index}`"
          class="neo_related-product"
        >
          <a :href="product.url">
            <div class="neo_related_image-holder">
              <NeoImage
                :src="product.imageSrc"
                :alt="'Gerelateerd product'"
              ></NeoImage>
            </div>
            <p class="neo__cta link">{{ product.title }}</p>
          </a>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import NeoImage from "./NeoImage.vue";

export default {
  name: "PartialsNeoCarouselRelatedProducts",
  components: {
    carousel: Carousel,
    slide: Slide,
    NeoImage,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    productsString: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    parsedProducts() {
      return JSON.parse(this.productsString);
    },
  },
};
</script>
