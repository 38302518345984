<template>
  <div class="savings-address-bar savings-block">
    <img class="savings-address-bar__image" :src="address.image" />
    <div class="savings-address-bar__content">
      <p>Je woning</p>
      <h2>
        {{ address.street }} {{ address.street_number }}<br />
        {{ address.postal_code }} {{ address.city }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "SavingsAddressBar",
  props: {
    address: {
      type: Object,
      default: null,
    },
  },
};
</script>
