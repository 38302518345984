<template>
  <div class="ui__content-tabs">
    <div
      v-if="$slots[`header__${activeContentTab}`] || $slots[`header__0`]"
      class="ui__header"
    >
      <slot
        v-if="$slots[`header__${activeContentTab}`]"
        :name="`header__${activeContentTab}`"
      ></slot>
      <slot v-else :name="`header__0`"></slot>
    </div>

    <div class="container ui__controls__container">
      <div class="ui__controls">
        <button
          v-for="(i, index) in controls"
          :key="`control--${index}`"
          class="ui__controls__btn"
          :class="[{ 'control--active': index === activeContentTab }]"
          @click="setActiveContentTab(index)"
        >
          {{ i.name }}
          <span v-if="i.pro" class="pro-label">Pro</span>
        </button>
      </div>
    </div>

    <div class="ui__content">
      <slot
        v-if="$slots[`content__${activeContentTab}`]"
        :name="`content__${activeContentTab}`"
      ></slot>
      <p v-else>({{ activeContentTab }}) - Deze tab heeft nog geen content</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiContentTabs",
  props: {
    hasHeader: {
      type: Boolean,
      default: false,
    },
    staticHero: {
      type: Boolean,
      default: true,
    },
    defaultTab: {
      type: Number,
      default: 0,
    },
    controls: {
      type: Array,
      default() {
        return [
          { name: "Consument", slug: "consument" },
          { name: "Professioneel", slug: "professioneel", pro: true },
        ];
      },
    },
  },
  data() {
    return {
      activeContentTab: 0,
    };
  },
  mounted() {
    if (window.location.hash) {
      this.checkIfHashInContentTabs(window.location.hash);
    } else {
      this.setActiveContentTab(this.defaultTab);
    }
  },
  methods: {
    checkIfHashInContentTabs(i) {
      const d = this.controls.findIndex(
        (e) =>
          e.name.toLowerCase() === i.replace("#", "").toLowerCase() ||
          e.slug.toLowerCase() === i.replace("#", "").toLowerCase()
      );
      this.setActiveContentTab(d > -1 ? d : this.defaultTab);
    },
    setActiveContentTab(i) {
      this.activeContentTab = this.activeContentTab !== i ? i : 0;
    },
  },
};
</script>
