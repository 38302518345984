<template>
  <div class="neo__loading">
    <div v-for="index in parseInt(12)" :key="`load__${index}`"></div>
  </div>
</template>

<script>
export default {
  name: "NeoLoading",
};
</script>
