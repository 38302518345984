<template>
  <div class="savings-loading">
    <Spinner />
    <h3 class="title">Resultaat ophalen</h3>
  </div>
</template>

<script>
import Spinner from "./../Spinner.vue";

export default {
  name: "SavingsLoading",
  components: {
    Spinner,
  },
};
</script>
